/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */

import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, cloneDeep } from 'lodash';
import { Card, Row } from 'reactstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { toast } from 'react-toastify';
import { formatFilterParams } from '../../helpers/commonHelpers';
import { updateDndStatus } from '../../services/api/contacts.service';
import { queryConversation, updateConversation } from '../../services/api/conversation.service';
import CustomTimeline from '../../components/Timeline';
import { tabs } from '../../constants/tabs';
import { WAITING_TIMING } from '../../constants/constants';
import emptyInbox from '../../assets/images/graphics/no-conversation.png';
import { setShowChangeGroupModal } from '../../store/inbox/actions';
import NoteList from './Modal/NoteList';
import DeleteModal from '../../components/Common/DeleteModal';
import {
  setLoader,
  setSelectedNotification,
  toggleCallWidgetVisibility,
  updateNotification,
} from '../../store/actions';
import CallWidget from '../../components/Features/CallWidget/CallWidget';
import ConversationBox from './InboxCommonComponents/ConversationBox';
import ProfileTab from './InboxCommonComponents/ProfileTab';
import {
  ALL_TAB,
  CONVERSATIONS_LIMIT,
  DND_TRIGGERS,
  DUMMY_TIMELINE_DATA,
  FILTER_KEYS,
  INBOX_INFO_TABS,
  MENTION_TAB,
  MINE_TAB,
  RESOLVED_TAB,
  UPDATE_CONVERSATION_KEYS,
} from '../../constants/channels/conversation.constant';
import { MESSAGE_TYPES } from '../../constants/inbox/message.constant';
import ChatConversation from './InboxCommonComponents/ChatConversation';
import TabMenu from '../../components/TabMenu';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { showToast } from '../../services/toastService';
import { toastType } from '../../constants/toast';

function InboxWhatsappMessage() {
  const userContext = useContext(UserContext); // Context to retrieve user data.
  const dispatch = useDispatch();
  const latestNotification = useSelector((state) => state.notification.latestNotification);
  const selectedNotification = useSelector((state) => state.notification.selectedNotification);
  const notifications = useSelector((state) => state.notification.notifications);
  const filter = useSelector((state) => state.inbox.filterData);
  const activeTab = useSelector((state) => state.inbox.activeTab);
  const [conversationList, setConversationList] = useState([]); // Sets the conversation list
  const [activeConversation, setActiveConversation] = useState({}); // Sets the selected or active conversation
  const [conversationMessages, setConversationMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterKey, setFilterKey] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [isDialPadOpen, setIsDialPadOpen] = useState(false);
  const [activeInfoTab, setActiveInfoTab] = useState(tabs.PROFILE);
  const [blockModal, setBlockModal] = useState(false);
  const [targetEvent, setTargetEvent] = useState(false);
  const [currentTab, setCurrentTab] = useState(ALL_TAB);
  const isFirstRender = useRef(true);
  const [showConversationInitiator, setShowConversationInitiator] = useState(false);
  const [initiatorProps, setInitiatorProps] = useState();

  const timer = useRef(null);

  const queryParams = new URLSearchParams(window.location.search);

  // Get values from query params
  const to = queryParams.get('to');
  const from = queryParams.get('from');
  const channel = queryParams.get('activeTab');
  const initiate = queryParams.get('initiate') === 'true';
  useEffect(() => {
    if (to && from) {
      console.info('initiate', initiate);
      if (initiate) {
        // Show conversation initiator
        setShowConversationInitiator(true);
        const props = {
          recipientNumber: to,
          senderNumber: from,
        };
        setInitiatorProps(props);
        console.info('Setting initiatorProps:', props);
        // Pass required props
      } else {
        // Open existing chat
        // You might want to fetch conversation details here
        const filter = `channel=${channel}`;
        const tabData = {
          key: 'from',
          value: from,
        };
        getMessageConversations(1, '', to, tabData, filter);
      }
    }
  }, [to, from, activeTab, initiate]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!isEmpty(filter) && currentTab === ALL_TAB) {
      const filterParams = formatFilterParams(filter);
      if (filterParams) {
        getMessageConversations(1, '', '', '', filterParams);
      }
    } else if (isEmpty(filter) && currentTab === ALL_TAB) {
      getMessageConversations(1);
    }
  }, [filter]);

  // useEffect hook to filter and update notifications whenever the conversation changes.
  // It creates an updatedNotificationArray by removing notifications that matches current conversation (from/to) ,indicating that they have been read.
  // Then, it dispatches an action to  update the notifications in the store and resets the conversationMessages state to an empty array.
  useEffect(() => {
    const updatedNotificationArray = notifications.filter((notification) => {
      if (
        notification?.from === activeConversation?.to
        && notification?.to === activeConversation?.from
      ) {
        return false;
      }
      return true;
    });
    dispatch(updateNotification(updatedNotificationArray));
    setConversationMessages([]);
  }, [activeConversation, notifications]);

  useEffect(() => {
    // Check if the current tab is the "Mention" tab.
    // If so, fetch conversations where the user is mentioned.
    if (currentTab === MENTION_TAB) {
      getMessageConversations(1, '', '', {
        key: UPDATE_CONVERSATION_KEYS.MENTIONED_USER_ID, // Key to filter by mentioned user ID
        value: true, // Value indicating to only fetch mentioned conversations
      });
    }
  }, [activeConversation?.notes]); // Re-run the effect when the notes of the active conversation change

  // Function to update conversation by conversation id
  const updateConversationById = async (conversationData, updateBody) => {
    try {
      let data;
      const newConversation = cloneDeep(conversationData);
      // If status is provided, update the conversation status.
      if (updateBody?.status) {
        data = {
          status: updateBody?.status,
        };
        newConversation.status = updateBody?.status;
        // If assignedUserId is provided, update the assigned user for the conversation.
      } else if (updateBody?.assignedUserId) {
        data = {
          assignedUserId: updateBody?.assignedUserId,
        };
        newConversation.assignedUserId = updateBody?.assignedUserId;
        // Add the userId to pinnedBy array if it's not already there.
        // Checks if isPinned is defined to handle both true/false cases, including null for unassigning.
      } else if (updateBody?.isPinned !== undefined) {
        data = {
          isPinned: updateBody?.isPinned,
        };
        if (
          updateBody?.isPinned === true
          && !activeConversation.pinedBy.includes(userContext?.userData?.id)
        ) {
          // Add the userId to pinnedBy array if it's not already there
          newConversation.pinedBy = [...activeConversation.pinedBy, userContext?.userData?.id];
        } else if (updateBody.isPinned === false) {
          // Remove the userId from pinnedBy array
          newConversation.pinedBy = activeConversation?.pinedBy.filter(
            (id) => id !== userContext?.userData?.id,
          );
        }
      } else {
        data = {
          isOpenedByUser: true,
        };
        newConversation.isOpenedByUser = true;
      }

      // Send the update request with the constructed data.
      const response = await updateConversation(conversationData?.id, data);
      if (response?.status) {
        const { userData } = userContext;
        // If the status was updated and the current tab is RESOLVED_TAB, refresh resolved conversations.
        if (updateBody?.status && currentTab === RESOLVED_TAB) {
          await getMessageConversations(page - 1, '', '', {
            key: UPDATE_CONVERSATION_KEYS.STATUS,
            value: RESOLVED_TAB,
          });
          // If assignedUserId or isPinned is updated and the current tab is MINE_TAB, refresh user-specific conversations.
        } else if (
          (updateBody?.assignedUserId || updateBody?.isPinned !== undefined)
          && currentTab === MINE_TAB
        ) {
          await getMessageConversations(page - 1, '', '', {
            key: UPDATE_CONVERSATION_KEYS.ASSIGNED_USER_ID,
            value: userData?.id,
          });
        }
        // Update the state with the modified conversation.
        setActiveConversation(newConversation);
      }
      return response;
    } catch (error) {
      console.error('Error at updateConversationById:', error);
      showToast({
        content: error?.message || 'Oops! something went wrong!',
        type: toastType.error,
      });
      return error;
    }
  };

  // Updates the conversations array when a notification from the notification list is selected.
  const updateConversations = async (conversationData) => {
    const {
      to, from, message, mediaType, mediaLink, conversationObj, timestamp,
    } = conversationData;
    try {
      const systemNumber = to?.replace(/\D/g, '');
      const contactNumber = from?.replace(/\D/g, '');
      let conversationFound = false;
      // Check if conversation matches the selected notification participants
      const updatedConversations = conversationList?.map((conversationToMatch) => {
        if (
          conversationToMatch?.to === contactNumber
          && conversationToMatch?.from === systemNumber
        ) {
          conversationFound = true;
          const objToAdd = {
            message,
            type: MESSAGE_TYPES.RECEIVED,
            isRead: false,
            isDelivered: true,
            mediaType,
            mediaLink,
            createdAt: new Date(),
          };

          // If this conversation is the active/selected one, update messages and details
          if (
            conversationToMatch?.to === activeConversation?.to
            && conversationToMatch?.from === activeConversation?.from
          ) {
            const updatedConversationMessages = [...conversationMessages, objToAdd].sort(
              (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
            );

            const updatedConversation = {
              ...conversationToMatch,
              updatedAt: new Date(),
              lastMessage: message,
              lastMediaType: mediaType,
              totalUnreadMessage: 0,
              lastMessageType: mediaType,
              lastMessageCreatedAt: timestamp,
            };

            // Sets the active/selected conversation with the updated data
            setActiveConversation(updatedConversation);
            // Sets the active/selected conversation's messages with the updated messages
            setConversationMessages(updatedConversationMessages);
            // Mark the conversation as read with no unread messages
            updateConversationById(updatedConversation);
            return updatedConversation;
          }
          // If not the active conversation, increment unread count
          return {
            ...conversationToMatch,
            updatedAt: new Date(),
            lastMessage: message,
            lastMediaType: mediaType,
            totalUnreadMessage: (conversationToMatch.totalUnreadMessage || 0) + 1,
            lastMessageType: mediaType,
            lastMessageCreatedAt: timestamp,
          };
        }

        // Return the conversation as-is if it's not the active conversation or no changes are needed
        return conversationToMatch;
      });

      // If no matching conversation is found and new data exists, add it as a new conversation
      if (!conversationFound && conversationObj) {
        updatedConversations.push({ ...conversationObj, updatedAt: new Date() });
      }

      // Sort conversations by most recent update time for display
      const sortedConversations = updatedConversations.sort(
        (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt),
      );

      // Set the conversations list after with the sortedConversations (sorted conversation list)
      setConversationList(sortedConversations);
    } catch (error) {
      console.error('error at updateConversations :', error);
    }
  };

  useEffect(() => {
    updateConversations(latestNotification);
  }, [latestNotification]);

  const onChatOpen = async (selectedConversation) => {
    setConversationMessages([]);
    const { userData } = userContext;
    // Updates the conversations array by setting totalUnreadMessage to 0 ,for the selected conversation, indicating all messages are read
    setConversationList((prevConversations) => {
      const index = prevConversations.findIndex(
        (conv) => conv?.to === selectedConversation?.to
          && conv?.from === selectedConversation?.from
          && selectedConversation?.channel === conv?.channel,
      );

      // If the conversation is found, update it; otherwise, return the original array
      if (index !== -1) {
        const updatedConversations = [...prevConversations];
        updatedConversations[index] = { ...updatedConversations[index], totalUnreadMessage: 0 };
        return updatedConversations;
      }

      return prevConversations;
    });

    setActiveConversation({ ...selectedConversation, totalUnreadMessage: 0 });
    setShowContactInfo(true);

    // The `totalUnreadMessage` should only be updated if the conversation is opened by the assigned user(owner); otherwise, it should remain unchanged.
    if (selectedConversation?.assignedUserId === userData?.id) {
      updateConversationById(selectedConversation);
    }
  };

  const onMessageClick = (conversationData = null) => {
    const queryParams = new URLSearchParams(window.location.search);
    const toNumber = queryParams.get('to');
    const defaultFrom = queryParams.get('from'); // Fallback value for `from`

    // Determine `from` based on conversationList availability
    const { from } = conversationList[0] || {};
    const selectedFrom = conversationList.length > 0 ? from : defaultFrom;

    if (!isEmpty(conversationData)) {
      const selectedConversation = conversationData.find(
        (conv) => conv.to === toNumber && conv.from === selectedFrom,
      );
      if (selectedConversation) {
        onChatOpen(selectedConversation);
      }
    } else if (conversationList.length > 0) {
      const selectedConversation = conversationList.find(
        (conv) => conv.to === toNumber && conv.from === selectedFrom,
      );
      if (selectedConversation) {
        onChatOpen(selectedConversation);
      }
    }
  };

  useEffect(() => {
    const { userData } = userContext;
    setPage(1);
    setTotalPages(1);
    const tabName = currentTab?.trim();
    setActiveConversation('');
    setShowContactInfo(false);
    switch (tabName) {
      case ALL_TAB: {
        getMessageConversations(1);
        break;
      }
      case MENTION_TAB: {
        getMessageConversations(1, '', '', {
          key: UPDATE_CONVERSATION_KEYS.MENTIONED_USER_ID,
          value: true,
        });
        break;
      }
      case RESOLVED_TAB: {
        getMessageConversations(1, '', '', {
          key: UPDATE_CONVERSATION_KEYS.STATUS,
          value: RESOLVED_TAB,
        });
        break;
      }
      case MINE_TAB: {
        getMessageConversations(1, '', '', {
          key: UPDATE_CONVERSATION_KEYS.ASSIGNED_USER_ID,
          value: userData?.id,
        });
        break;
      }
      default: {
        // getMessageConversations(1);
        break;
      }
    }
  }, [currentTab]);

  const getMessageConversations = async (page = 1, key = '', query = '', tabData, filter) => {
    try {
      // dispatch(setLoader(true));
      if (page <= totalPages) {
        let isOpenedByUser = '';
        switch (key) {
          case FILTER_KEYS.READ:
            isOpenedByUser = 'true';
            break;
          case FILTER_KEYS.UNREAD:
            isOpenedByUser = 'false';
            break;
          default:
            isOpenedByUser = '';
            break;
        }
        const response = await queryConversation(page, isOpenedByUser, query, tabData, filter);
        if (response?.status) {
          const newConversations = response?.data?.results;
          setConversationList((prevConversations) => {
            const existingIds = new Set(prevConversations.map((conv) => conv.id));
            const filteredNewConversations = newConversations.filter(
              (conv) => !existingIds.has(conv.id),
            );
            const updatedConversations = page === 1
              ? [...newConversations]
              : [...prevConversations, ...filteredNewConversations];

            // Call onMessageClick immediately with the new conversation data
            onMessageClick(updatedConversations);

            return updatedConversations;
          });
          setPage((prevPage) => prevPage + 1);
          setTotalPages(response?.data?.totalPages ? response?.data?.totalPages : 1);
        }
      }
    } catch (error) {
      console.error('error at getMessageConversations :', error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  // Handles notification click events for different messaging platforms
  const onNotificationClick = () => {
    // Exit early if required parameters are missing
    if (!selectedNotification?.index) return;
    // Helper function to reset conversation state
    const resetConversationState = (searchPhoneNumber) => {
      setPage(1);
      setConversationList([]);
      setActiveConversation({});
      setSearchQuery(searchPhoneNumber);
      setFilterKey('');
      getMessageConversations(1, '', searchPhoneNumber);
    };

    // Helper function to format phone number by removing non-digits
    const formatPhoneNumber = (number) => number?.replace(/\D/g, '');

    if (selectedNotification?.from) {
      resetConversationState(formatPhoneNumber(selectedNotification?.from));
      dispatch(setSelectedNotification(''));
    }
  };

  useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    [],
  );

  useEffect(() => {
    onNotificationClick();
  }, [selectedNotification]);

  const toggleDialPad = () => {
    setIsDialPadOpen((prev) => !prev);
  };

  const toggleChangeGroup = () => {
    dispatch(setShowChangeGroupModal());
  };

  const handleClickOutside = (event) => {
    setTargetEvent(event);
  };

  const onClickBlock = () => {
    setBlockModal(true);
  };

  const onYReachEnd = () => {
    if (conversationList.length >= CONVERSATIONS_LIMIT) {
      getMessageConversations(page, filterKey);
    }
  };

  const debounceSearch = (value) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setConversationList([]);
      getMessageConversations(page, filterKey, value);
    }, WAITING_TIMING);
  };

  const searchUsers = (event) => {
    const { value } = event.target;
    if (!value) {
      setSearchQuery('');
      getMessageConversations(1);
      return;
    }
    setPage(1);
    setTotalPages(1);
    setSearchQuery(value);
    debounceSearch(value);
  };

  const handleBlockContact = async () => {
    try {
      const block = true;
      const response = await updateDndStatus({
        phoneNumbers: [activeConversation?.to],
        block,
        dndTrigger: DND_TRIGGERS.BLOCKED,
      });

      if (response?.status) {
        toast.success(response?.message || 'Contact blocked successfully!');
        await getMessageConversations(page);
        setConversationList((prev) => prev.filter((prevConversation) => prevConversation?.id !== activeConversation?.id));
        setBlockModal(false);
        setShowContactInfo(false);
        setActiveConversation({});
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
        setBlockModal(false);
      }
    } catch (error) {
      console.error('error at handleBlockContact :', error);
    }
  };

  const updateProfileDetails = (conversationId, newName) => {
    setConversationList((prevConversations) => {
      const index = prevConversations.findIndex(
        (conversation) => conversation?.id === conversationId,
      );
      if (index === -1) return prevConversations;

      const newConversations = [...prevConversations];
      newConversations[index] = { ...newConversations[index], name: newName };
      return newConversations;
    });
  };

  const handleInfoTabChange = (index) => {
    setActiveInfoTab(INBOX_INFO_TABS?.[index]?.value);
  };

  const customizedMarker = (item) => (
    <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 border border-1 rounded timeline-con-div">
      <i className={`${item.icon} p-1 text-primary font-size-18`} />
    </span>
  );

  const customizedContent = (item) => (
    <p className="d-flex flex-column">
      <span className="mb-2 timeline-time">
        <i className="mdi mdi-clock-outline font-size-20 me-1" />
        {item?.date}
      </span>
      <span>{item?.content}</span>
    </p>
  );

  document.title = 'Inbox | Whatsapp';
  return (
    <div className="inbox" onClick={handleClickOutside}>
      {blockModal ? (
        <DeleteModal
          show={blockModal}
          buttonText="Yes, block it!"
          onDeleteClick={handleBlockContact}
          onCloseClick={() => setBlockModal(false)}
        />
      ) : (
        ''
      )}
      {isDialPadOpen ? (
        <CallWidget
          isOpenDialPad={isDialPadOpen}
          toggleDialPad={toggleDialPad}
          to={`+${activeConversation?.to}`}
          from={`+${activeConversation?.from}`}
        />
      ) : (
        ''
      )}
      <Row className="h-100">
        <div className="d-flex p-0">
          <ConversationBox
            conversationList={conversationList}
            activeConversation={activeConversation}
            onSetConversationList={(newConversations) => setConversationList(newConversations)}
            onSetActiveConversation={(newConversation) => setActiveConversation(newConversation)}
            searchQuery={searchQuery}
            searchUsers={searchUsers}
            onYReachEnd={onYReachEnd}
            userChatOpen={(userConversation) => onChatOpen(userConversation)}
            onSetCurrentTab={(newTab) => setCurrentTab(newTab)}
            getConversationLists={getMessageConversations}
            initiatorProps={initiatorProps}
            showConversationInitiator={showConversationInitiator}
          />
          {!isEmpty(activeConversation) ? (
            <ChatConversation
              targetEvent={targetEvent}
              activeConversation={activeConversation}
              onSetActiveConversation={(newConversation) => setActiveConversation(newConversation)}
              onSetConversationList={(newConversations) => setConversationList(newConversations)}
              showContactInfo={showContactInfo}
              onToggleContactInfo={() => setShowContactInfo((prevShowContactInfo) => !prevShowContactInfo)}
              handleUpdateConversation={(conversationData, updateBody) => updateConversationById(conversationData, updateBody)}
            />
          ) : (
            <Card className="mb-0 w-100">
              <div className="d-flex align-items-center justify-content-center h-100">
                <img src={emptyInbox} alt="empty inbox" className="w-50" />
              </div>
            </Card>
          )}
          {showContactInfo ? (
            <div className="chat-leftsidebar chat-left-div">
              <TabMenu
                model={INBOX_INFO_TABS}
                activeIndex={INBOX_INFO_TABS?.findIndex((tab) => tab?.value === activeInfoTab)}
                onTabChange={handleInfoTabChange}
                className="d-flex justify-content-start mb-3"
                itemClassName="font-size-14"
                displayLabel
                displayIcon
              />
              {activeInfoTab === tabs.PROFILE ? (
                <ProfileTab
                  activeConversation={activeConversation}
                  onClickBlock={onClickBlock}
                  toggleChangeGroup={toggleChangeGroup}
                  toggleCallWidgetVisibility={toggleCallWidgetVisibility}
                  activeTab={activeTab}
                  updateProfileDetails={updateProfileDetails}
                  handleUpdateConversation={(conversationData, updateBody) => updateConversationById(conversationData, updateBody)}
                />
              ) : null}
              {activeInfoTab === tabs.NOTES ? (
                <NoteList
                  activeConversation={activeConversation}
                  onSetActiveConversation={(newConversation) => setActiveConversation(newConversation)}
                  onSetConversationList={(newConversations) => setConversationList(newConversations)}
                />
              ) : null}
              {activeInfoTab === tabs.TIMELINE ? (
                <div className="inbox-right-tab">
                  <CustomTimeline
                    events={DUMMY_TIMELINE_DATA}
                    content={customizedContent}
                    align="left"
                    marker={customizedMarker}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </Row>
    </div>
  );
}

InboxWhatsappMessage.propTypes = {};

export default InboxWhatsappMessage;
