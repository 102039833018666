import React, {
  useEffect, useContext, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import { CHANNEL_CONNECT_STATUS, CHANNEL_TYPES_SETTINGS } from '../../../../constants/channels/channels.constant';
import { setShowTwilioCredentialsModal } from '../../../../store/actions';
import { UserContext } from '../../../UserProvider/UserProvider';
import { getChannels } from '../../../../utils/channels';
import { updateOrganization } from '../../../../services/api/organization.service';
import { fetchPurchasedPhoneNumbers, updatePhoneNumbersStatus } from '../../../../services/api/phoneNumber.service';
import { NUMBER_STATUS } from '../../../../constants/status.constant';

const StyledDialog = styled(Dialog)`
  .p-dialog-content {
    padding: 0 1.5rem 1.5rem;
    width: 30rem;
  }
`;

const FormRow = styled.div`
  margin-bottom: 1.5rem
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-right: 0.5rem;
`;

const ErrorMessage = styled.small`
  color: #f44336;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

function TwilioCredentialsModal({ getPrivateCredentials, privateCreds }) {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.channels.showTwilioCredentialsModal);
  const { userData } = useContext(UserContext);
  const toast = useRef(null);

  const initialValues = {
    coPilotToken: '',
    accountSid: '',
    authToken: '',
  };

  const toggleModal = () => {
    dispatch(setShowTwilioCredentialsModal());
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  const handleUpdateTwilioCredentials = async (updateBody) => {
    try {
      const organizationId = userData?.organizationId;
      const response = await updateOrganization(organizationId, updateBody);
      if (response?.status) {
        getPrivateCredentials();
        showToast('success', 'Success', response?.message || 'Twilio credentials updated successfully!');
        if (updateBody.channels[0].status === CHANNEL_CONNECT_STATUS.CONNECTED) {
          // When Twilio is connected, fetch the purchased phone numbers.
          await fetchPurchasedPhoneNumbers();
        } else {
          // Otherwise (disconnected), call your alternate API.
          await updatePhoneNumbersStatus(NUMBER_STATUS.INACTIVE);
        }
        toggleModal();
      } else {
        showToast('error', 'Error', response?.message || 'Oops! Something went wrong');
      }
    } catch (error) {
      console.error('Error while updating Twilio credentials:', error);
      showToast('error', 'Error', 'Failed to update Twilio credentials');
    }
  };
  function createUpdateBody(config, status) {
    return {
      channels: [
        {
          type: CHANNEL_TYPES_SETTINGS.TWILIO,
          status,
          config,
        },
      ],
    };
  }
  function handleSubmit(values) {
    const twilioCreds = {
      sid: values.accountSid,
      authToken: values.authToken,
      copilotId: values.coPilotToken,
    };

    const updateBody = createUpdateBody(twilioCreds, CHANNEL_CONNECT_STATUS.CONNECTED);
    handleUpdateTwilioCredentials(updateBody);
  }

  const validationSchema = Yup.object().shape({
    accountSid: Yup.string().required('Account SID is required'),
    authToken: Yup.string().required('Auth Token is required'),
    coPilotToken: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const setCredentials = () => {
    const { twilio } = getChannels(privateCreds);
    formik.setValues({
      coPilotToken: twilio?.config?.copilotId || '',
      accountSid: twilio?.config?.sid || '',
      authToken: twilio?.config?.authToken || '',
    });
  };

  useEffect(() => {
    setCredentials();
  }, [privateCreds]);

  const handleDisconnectTwilio = async () => {
    const disconnectBody = createUpdateBody({
      sid: '',
      authToken: '',
      copilotId: '',
    }, CHANNEL_CONNECT_STATUS.DISCONNECTED);
    handleUpdateTwilioCredentials(disconnectBody);
  };

  const renderFormField = (fieldName, label, tooltipText) => (
    <FormRow>
      <LabelWrapper>
        <Label htmlFor={fieldName}>{label}</Label>
        <Tooltip target={`.${fieldName}-info`}>
          {tooltipText}
        </Tooltip>
        <i className={`bx bx-info-circle mb-2 ${fieldName}-info`} />
      </LabelWrapper>
      <InputText
        style={{ width: '100%' }}
        id={fieldName}
        name={fieldName}
        value={formik.values[fieldName]}
        onChange={formik.handleChange}
        className={formik.errors[fieldName] && formik.touched[fieldName] ? 'p-invalid' : ''}
      />
      {formik.errors[fieldName] && formik.touched[fieldName] && (
        <ErrorMessage>{formik.errors[fieldName]}</ErrorMessage>
      )}
    </FormRow>
  );

  const renderFooter = () => {
    const { twilio } = getChannels(privateCreds);
    return (
      <ButtonWrapper>
        {twilio?.status === CHANNEL_CONNECT_STATUS.CONNECTED && (
          <Button
            label="Disconnect"
            icon="bx bx-disconnect"
            className="p-button-outlined p-button-danger"
            onClick={handleDisconnectTwilio}
            style={{ borderRadius: 'var(--border-radius)' }}
          />
        )}
        <Button
          label="Save"
          size="small"
          className="p-button-success p-button-outlined"
          onClick={formik.handleSubmit}
          style={{ borderRadius: 'var(--border-radius)' }}
        />
      </ButtonWrapper>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <StyledDialog
        header="Twilio Credentials"
        visible={isModalOpen}
        onHide={toggleModal}
        footer={renderFooter()}
      >
        <form onSubmit={formik.handleSubmit}>
          {renderFormField('accountSid', 'Account SID', 'Enter your Twilio Account SID here.')}
          {renderFormField('authToken', 'Auth Token', 'Enter your Twilio Auth Token here.')}
          {renderFormField('coPilotToken', 'Co-Pilot ID', 'Enter your Twilio Co-Pilot ID here.')}
        </form>
      </StyledDialog>
    </>
  );
}

TwilioCredentialsModal.propTypes = {
  getPrivateCredentials: PropTypes.func.isRequired,
  privateCreds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TwilioCredentialsModal;
