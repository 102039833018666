import React, {
  useCallback,
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { classNames } from 'primereact/utils';
import { sortByCreatedAt } from '../../../helpers/commonHelpers';
import { LIMIT } from '../../../constants/constants';
import { initiateNewConversation } from '../../../services/api/conversation.service';
import { CHANNEL_TYPES } from '../../../constants/channels/channels.constant';
import { getContacts } from '../../../services/api/contacts.service';
import { fetchAssignedPhoneNumbers } from '../../../services/api/phoneNumber.service';
import { fetchAssignedWhatsappNumbers } from '../../../services/api/whatsappNumber.service';
import Dropdown from '../../../components/Dropdown';
import CustomOverlay from '../../../components/Overlay';
import Textarea from '../../../components/Textarea';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import Button from '../../../components/Button';
import {
  ASSIGNED_USER_TYPE,
  LAZY_LOAD_DELAY,
} from '../../../constants/channels/conversation.constant';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import { setLoader } from '../../../store/actions';
import Skeleton from '../../../components/Skeleton';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { NUMBER_STATUS } from '../../../constants/status.constant';

function ConversationInitiator({
  onSetConversationList, // set the conversation list
  onSetActiveConversation, // set the selected or active conversation
  recipientNumber,
  senderNumber,
  showConversationInitiator,
}) {
  const userContext = useContext(UserContext); // Access the context here
  const [selectedFromNumber, setSelectedFromNumber] = useState(null);
  const [selectedToNumber, setSelectedToNumber] = useState([]);
  const [fromNumberOptions, setFromNumberOptions] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [totalContacts, setTotalContacts] = useState(0);
  const loadedBatchesRef = useRef(new Set());
  const contactsRef = useRef([]);
  const loadLazyTimeout = useRef(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const dispatch = useDispatch();
  // Initialize the ref with empty placeholder array based on total items expected
  // We'll fill this gradually as user scrolls
  const initializeContacts = async () => {
    try {
      const response = await getContacts(1, LIMIT);
      if (response?.status) {
        const totalItems = response?.data?.totalResults; // Assuming API returns total count
        contactsRef.current = Array.from({ length: totalItems });

        // Fill in the first batch of items
        const initialContacts = response?.data?.results?.map((contact) => ({
          label: `${contact?.firstName} ${contact?.lastName}`,
          phoneNumber: contact?.phoneNumberString,
          value: contact?.id,
          icon: 'mdi mdi-account',
        }));

        // Update only the first portion of the array
        contactsRef.current.splice(0, initialContacts.length, ...initialContacts);
      }
    } catch (error) {
      console.error('Error initializing contacts:', error);
    }
  };
  const handleAccept = () => {
    // Redirect to channel settings
    window.location.href = '/communication-settings'; // Replace with actual route
  };

  const handleReject = () => {
    setDialogVisible(false); // Close the dialog
  };
  // Initial setup
  useEffect(() => {
    initializeContacts();
  }, []);

  useEffect(() => {
    if (showConversationInitiator) {
      setIsOpen(true);

      if (senderNumber && fromNumberOptions?.length) {
        const matchingFromNumber = fromNumberOptions.find(
          (option) => option.phoneNumber.replace(/\D/g, '') === senderNumber.replace(/\D/g, ''),
        );
        if (matchingFromNumber && selectedFromNumber !== matchingFromNumber.value) {
          setSelectedFromNumber(matchingFromNumber.value);
        }
      }

      if (recipientNumber && contactsRef.current?.length) {
        const matchingToNumber = contactsRef.current.find(
          (contact) => contact?.phoneNumber?.replace(/\D/g, '') === recipientNumber.replace(/\D/g, ''),
        );
        if (matchingToNumber && selectedToNumber !== matchingToNumber.value) {
          setSelectedToNumber(matchingToNumber.value);
        }
      }
    } else {
      setIsOpen(false);
    }
  }, [showConversationInitiator, senderNumber, recipientNumber, fromNumberOptions, selectedFromNumber, selectedToNumber]);

  // Function to retrieve contacts in batches.
  const fetchContactsInBatch = async (start) => {
    try {
      const pageSize = LIMIT;
      const pageNum = Math.floor(start / pageSize) + 1;

      // Check if this batch is already loaded
      if (loadedBatchesRef.current.has(pageNum)) {
        setLoading(false);
        return [];
      }

      const response = await getContacts(pageNum, pageSize);
      if (response?.status) {
        // Store total count on first load
        if (!totalContacts) {
          setTotalContacts(response?.data?.totalResults || 0);
        }

        // Mark this batch as loaded
        loadedBatchesRef.current.add(pageNum);

        return response?.data?.results?.map((contact) => ({
          label: `${contact?.firstName} ${contact?.lastName}`,
          phoneNumber: contact?.phoneNumberString,
          value: contact?.id,
          address: contact?.address,
          icon: 'mdi mdi-account',
        }));
      }
      return [];
    } catch (error) {
      console.error('Error fetching contacts batch:', error);
      return [];
    }
  };

  // PrimeReact VirtualScroller onLazyLoad handler.
  const onLazyLoad = (event) => {
    if (loading) return;

    setLoading(true);

    if (loadLazyTimeout.current) {
      clearTimeout(loadLazyTimeout.current);
    }

    loadLazyTimeout.current = setTimeout(async () => {
      const { first } = event;

      try {
        // Don't fetch if we've reached the end
        if (totalContacts && first >= totalContacts) {
          setLoading(false);
          return;
        }

        const newContacts = await fetchContactsInBatch(first);

        if (newContacts.length > 0) {
          const _contacts = [...contactsRef?.current];
          for (let i = 0; i < newContacts.length; i += 1) {
            _contacts[first + i] = newContacts[i];
          }
          contactsRef.current = _contacts;
        }
      } finally {
        setLoading(false);
      }
    }, LAZY_LOAD_DELAY);
  };

  useEffect(() => {
    const componentDidMountAsync = async () => {
      const [phoneNumbersResult, whatsappNumbersResult] = await Promise.allSettled([
        fetchAssignedPhoneNumbers(),
        fetchAssignedWhatsappNumbers(),
      ]);

      const phoneNumbers = phoneNumbersResult.status === 'fulfilled' ? phoneNumbersResult.value : [];
      const whatsappNumbers = whatsappNumbersResult.status === 'fulfilled' ? whatsappNumbersResult.value : [];

      const mappedPhoneNumbers = phoneNumbers?.map((number, index) => ({
        label: number?.name || `Text Number ${index}`,
        phoneNumber: number?.phoneNumber,
        value: number?.id,
        channel: CHANNEL_TYPES.TEXT,
        icon: 'mdi mdi-message-text-outline text-primary',
        status: number?.status,
      }));
      const mappedWhatsappNumbers = whatsappNumbers?.map((number) => ({
        label: number?.name,
        phoneNumber: number?.phoneNumber,
        value: number?._id,
        channel: CHANNEL_TYPES.WHATSAPP,
        icon: 'mdi mdi-whatsapp text-success',
        status: number?.status,
      }));
      const fromNumbers = [...mappedPhoneNumbers, ...mappedWhatsappNumbers];
      const sortedFromNumbers = fromNumbers.sort((a, b) => {
        if (a.status === NUMBER_STATUS.INACTIVE && b.status !== NUMBER_STATUS.INACTIVE) return 1;
        if (a.status !== NUMBER_STATUS.INACTIVE && b.status === NUMBER_STATUS.INACTIVE) return -1;
        return 0;
      });
      setFromNumberOptions(sortedFromNumbers);
    };

    componentDidMountAsync();
  }, []);

  // Template to render items with icons, labels, and numbers
  const itemTemplate = (option) => {
    // Check if the option is inactive
    const isInactive = option?.status === NUMBER_STATUS.INACTIVE;

    const handleClick = (e) => {
      if (isInactive) {
        e.stopPropagation(); // Prevent event propagation if inactive
        e.preventDefault(); // Prevent selection if inactive
      }
    };

    return (
      <div
        className={`p-dropdown-item d-flex p-0 ${isInactive ? 'disabled' : ''}`}
        style={{
          opacity: isInactive ? 0.5 : 1, // Reduce opacity for inactive option
        }}
        onClick={handleClick} // Handle click to prevent selection if inactive
      >
        <div className="d-flex align-items-center me-2">
          {option.icon && <i className={`item-icon ${option.icon} font-size-22 me-2`} />}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center gap-2">
            <span
              className={`item-label font-size-14 ${isInactive ? 'text-muted' : ''}`} // Use text-muted for disabled text
              style={{
                cursor: isInactive ? 'not-allowed' : 'pointer', // Change cursor for inactive
              }}
            >
              {option.label}
            </span>
            {isInactive && (
              <span className="badge bg-danger rounded-pill px-2 py-1" style={{ fontSize: '0.7rem' }}>
                Inactive
              </span>
            )}
          </div>
          {/* Phone number also disabled when option is inactive */}
          <span
            className="item-number"
            style={{
              cursor: isInactive ? 'not-allowed' : 'pointer', // Disable click on phone number
              color: isInactive ? '#b0b0b0' : '', // Grayed out if inactive
            }}
          >
            <PhoneNumberWithFlag phoneNumber={option?.phoneNumber} />
          </span>
        </div>
      </div>
    );
  };

  // Template for the selected value
  const valueTemplate = (option) => {
    if (option) {
      return (
        <div className="p-dropdown-value d-flex align-items-center p-0">
          <div className="d-flex align-items-center me-1">
            {option.icon && <i className={`item-icon ${option.icon} font-size-22`} />}
          </div>
          <div className="d-flex">
            <span className="item-label font-size-14">{option?.label}</span>
          </div>
        </div>
      );
    }
    return <span>Select ...</span>;
  };

  const handleInitateConversation = useCallback(async () => {
    try {
      dispatch(setLoader(true));
      const currentUser = userContext?.userData;
      const selectedContactDetails = contactsRef?.current?.find(
        (number) => number?.value === selectedToNumber,
      );
      const selectedFromNumberDetails = fromNumberOptions?.find(
        (number) => number?.value === selectedFromNumber,
      );
      const data = {
        channel: selectedFromNumberDetails?.channel,
        from: selectedFromNumberDetails?.phoneNumber?.replace(/\D/g, ''),
        to: selectedContactDetails?.phoneNumber?.replace(/\D/g, ''),
        name: selectedContactDetails?.label,
        address: selectedContactDetails?.address,
        message: newMessage,
        contactId: selectedToNumber,
        assignedUserId: currentUser?.id,
        assignedUserType: ASSIGNED_USER_TYPE.USER,
      };
      const response = await initiateNewConversation(data);
      if (response) {
        if (response?.status === NUMBER_STATUS.INACTIVE) {
          setDialogVisible(true); // Show confirm dialog
          return;
        }
        // Check if the response indicates an existing conversation
        if (response?.exist) {
          // If an existing conversation exists, set it as the active conversation
          setNewMessage('');
          setSelectedFromNumber('');
          setSelectedToNumber('');
          setIsOpen(false);
          onSetActiveConversation(response?.doc); // Assuming the existing conversation is returned in the response
        } else {
          // Handle the case where a new conversation is created
          setNewMessage('');
          setSelectedFromNumber('');
          setSelectedToNumber('');
          setIsOpen(false);
          onSetConversationList((prev) => sortByCreatedAt([...prev, response?.doc]));
          onSetActiveConversation(response?.doc);
        }
      }
    } catch (error) {
      console.error('error at handleInitateConversation:', error);
    } finally {
      dispatch(setLoader(false));
    }
  }, [dispatch, fromNumberOptions, newMessage, onSetActiveConversation, onSetConversationList, selectedFromNumber, selectedToNumber, userContext]);

  const loadingTemplate = (options) => {
    const className = classNames('flex align-items-center p-2', {
      odd: options.odd,
    });

    return (
      <div className={className} style={{ height: '50px' }}>
        <Skeleton width={options.even ? '90%' : '70%'} height="1.5rem" />
      </div>
    );
  };

  return (
    <>
      <ConfirmDialog
        visible={dialogVisible}
        message="The WhatsApp number is inactive. Do you want to go and update into channel settings"
        header="Channel Inactive"
        accept={handleAccept}
        reject={handleReject}
        onHide={() => setDialogVisible(false)}
        acceptClassName="p-button-success" // Success style
        rejectClassName="p-button-danger" // Danger style
        acceptLabel="Go to Settings"
        rejectLabel="Cancel"
      />
      <div className="">
        <CustomOverlay
          buttonContent="New"
          buttonIcon="mdi mdi-plus-circle me-1"
          className="bg-secondary text-light"
          isOpen={isOpen}
        >
          <div className="d-flex flex-column font-size-14">
            <div className="mb-3">
              <span className="fw-bold fs-4">Initiate a new conversation</span>
            </div>
            <div className="mb-3">
              <span className="me-4 fw-500">Sender Number :</span>
              <Dropdown
                value={selectedFromNumber}
                options={fromNumberOptions}
                onChange={(e) => setSelectedFromNumber(e.value)}
                optionLabel="label"
                placeholder="Select ..."
                className="width-15rem"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
              />
            </div>
            <div className="mb-3">
              <span className="me-2 fw-500">Recipient Number :</span>
              <Dropdown
                value={selectedToNumber}
                options={contactsRef.current}
                onChange={(e) => setSelectedToNumber(e.value)}
                optionLabel="label"
                placeholder="Select..."
                className="width-15rem w-full md:w-14rem"
                itemTemplate={itemTemplate}
                valueTemplate={valueTemplate}
                virtualScrollerOptions={{
                  lazy: true,
                  onLazyLoad,
                  itemSize: 60,
                  showLoader: true,
                  loading,
                  delay: 250,
                  spacerSize: 0,
                  items: contactsRef?.current,
                  loadingTemplate,
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="" className="align-top me-4">
                Enter Message :
              </label>
              <Textarea
                name="message"
                value={newMessage}
                placeholder="Enter your message here..."
                onChange={(e) => setNewMessage(e.target.value)}
                rows={4}
                cols={30}
                invalid={false}
                valid
                className="ms-1"
                autoResize
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                label="Send"
                className="bg-primary"
                onClick={() => handleInitateConversation()}
                disabled={!selectedToNumber || !selectedFromNumber || !newMessage}
              />
            </div>
          </div>
        </CustomOverlay>
      </div>
    </>

  );
}

ConversationInitiator.propTypes = {
  onSetConversationList: PropTypes.func,
  onSetActiveConversation: PropTypes.func,
  recipientNumber: PropTypes.string,
  senderNumber: PropTypes.string,
  showConversationInitiator: PropTypes.bool,
};

export default ConversationInitiator;
