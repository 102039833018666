import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Badge, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../utils/general';
import { callType } from '../../constants/voice/call.constant';
import { axiosGet } from '../../services/http.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { setLoader } from '../../store/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import { BADGE_COLORS, TEXT_COLORS } from '../../constants/status.constant';
import DataGrid from '../../components/DataTable';

class CallLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callLogs: [],
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      searchQuery: '',
      selectedRows: [],
      callLogsColumns: [
        {
          header: 'Time',
          accessorKey: 'createdAt',
          Cell: ({ renderedCellValue }) => {
            const formattedDate = moment(renderedCellValue).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },

        {
          header: 'Name',
          accessorKey: 'name',
        },
        {
          header: 'To',
          accessorKey: 'to',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'From',
          accessorKey: 'from',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Type',
          accessorKey: 'type',
          formatter({ row }) {
            const log = row?.original;
            if (log.type === 'inbound') {
              return (
                <Badge color="success-subtle" pill className="fs-6 text-success">
                  <i className="mdi mdi-phone-incoming" />
                  {' '}
                  {capitalizeFirstLetter(callType.INBOUND)}
                </Badge>
              );
            }
            return (
              <Badge color="primary-subtle" pill className="fs-6 text-primary">
                <i className="mdi mdi-phone-outgoing" />
                {' '}
                {capitalizeFirstLetter(callType.OUTBOUND)}
              </Badge>
            );
          },
        },
        {
          header: 'Duration',
          accessorKey: 'duration',
        },
        {
          header: 'Status',
          accessorKey: 'status',
          Cell: ({ row }) => {
            const log = row?.original;
            return (
              <Badge
                color={BADGE_COLORS[log?.status]}
                pill
                className={`fs-6 ${TEXT_COLORS[log?.status]}`}
              >
                {log?.status}
              </Badge>
            );
          },
        },
        {
          header: 'Recording',
          accessorKey: 'recordingUrl',
          Cell: ({ renderedCellValue }) => (
            <audio controls>
              <source src={renderedCellValue} type="audio/mpeg" />
            </audio>
          ),
        },
        {
          header: 'Cost',
          accessorKey: 'cost',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>,
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: () => <span>-</span>,
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetCallLogs(page);
  }

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetCallLogs(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetCallLogs(1, newPageSize);
    });
  };

  handleGetCallLogs = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      // onSetLoader(true);
      const response = await axiosGet(
        `calldailer?isVoicemail=false&page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        this.setState({
          callLogs: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting call logs :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetCallLogs(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'call-logs';

    const {
      callLogs, deleteModal, callLogsColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Logs" breadcrumbItem="Call Logs" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="mt-3">
                      <DataGrid
                        data={callLogs}
                        columns={callLogsColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

CallLogs.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallLogs));
