import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button, Badge,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../../constants/constants';

// Import Breadcrumb
import Number from '../../PhoneNumber/Modal/Number';
import { axiosDel, axiosGet } from '../../../services/http.service';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import { setShowNumberModal } from '../../../store/phone-number/actions';
import UserForm from './Modal/UserForm';
import { setLoader } from '../../../store/actions';
import { UserContext } from '../../../components/UserProvider/UserProvider';
import PhoneNumberWithFlag from '../../../components/PhoneNumberWithFlag';
import { USER_STATUS } from '../../../constants/status.constant';
import { ROLES } from '../../../constants/users/role.constant';
import DataGrid from '../../../components/DataTable';

class User extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user: '',
      showUserModal: false,
      deleteModal: false,
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      searchQuery: '',
      workspaceList: [],
      selectedRows: [],
      userColumns: [
        {
          header: 'User',
          accessorKey: 'name',
        },
        {
          header: 'Email',
          accessorKey: 'email',
        },
        {
          header: 'Phone',
          accessorKey: 'phoneNumber',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Twilio Numbers',
          accessorKey: 'assignNumbers',
          Cell: ({ renderedCellValue }) => (
            <div className="">
              {renderedCellValue?.length ? (
                renderedCellValue?.map((twilioNumber) => (
                  <span
                    key={`${twilioNumber?.phoneNumber}`}
                    className="badge rounded-pill bg-primary fs-6"
                  >
                    {twilioNumber?.phoneNumber}
                  </span>
                ))
              ) : (
                <span className="badge rounded-pill bg-primary fs-6">
                  {renderedCellValue?.length}
                </span>
              )}
            </div>
          ),
        },
        {
          header: 'Whatsapp Numbers',
          accessorKey: 'assignedWhatsappNumbers',
          Cell: ({ renderedCellValue }) => (
            <div className="d-flex flex-wrap">
              {renderedCellValue?.length ? (
                renderedCellValue?.map((number) => (
                  <div key={`${number?.phoneNumber}`} className="mb-2">
                    <span
                      key={`${number?.phoneNumber}`}
                      className="badge rounded-pill bg-primary fs-6 me-1"
                    >
                      {number?.phoneNumber}
                    </span>
                  </div>
                ))
              ) : (
                <span className="badge rounded-pill bg-primary fs-6">
                  {renderedCellValue?.length}
                </span>
              )}
            </div>
          ),
        },
        {
          header: 'Total Number',
          accessorKey: 'assignedNumberLength',
          Cell: ({ renderedCellValue }) => (
            <Badge color="primary-subtle" pill className="text-primary fs-6 pt-1">
              {renderedCellValue}
            </Badge>
          ),
        },
        {
          header: 'Status',
          accessorKey: 'status',
          Cell: ({ renderedCellValue }) => (
            <Badge
              color={renderedCellValue === USER_STATUS.ACTIVE ? 'success-subtle' : 'danger-subtle'}
              pill
              className={
                renderedCellValue === USER_STATUS.ACTIVE ? 'text-success fs-6' : 'text-danger fs-6'
              }
            >
              {renderedCellValue}
            </Badge>
          ),
        },
        {
          header: 'workspaces',
          accessorKey: 'assignedWorkspaces',
          Cell: ({ renderedCellValue }) => (
            <div className="d-flex">{renderedCellValue.map((cell) => cell.name).join(', ')}</div>
          ),
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const user = row?.original;
            return (
              <div className="d-flex gap-3">
                {user.role === ROLES.ADMIN ? (
                  <Link to={`/login/${user.id}`}>
                    <Badge color="primary-subtle" className="text-primary">
                      <i
                        className="mdi mdi-login-variant font-size-18"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="login"
                        id="login"
                      />
                    </Badge>
                  </Link>
                ) : (
                  ''
                )}
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="mdi mdi-account-edit-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="edit"
                      onClick={() => this.handleUserClick(user)}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="warning-subtle" className="text-warning">
                    <i
                      className="mdi mdi-pencil-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="assign number"
                      onClick={() => this.handleReassign()}
                    />
                  </Badge>
                </Link>
                <Link to="#">
                  <Badge color="danger-subtle" className="text-danger">
                    <i
                      className="mdi mdi-trash-can-outline font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="delete"
                      onClick={() => this.onClickDelete(user)}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.getAllUsers(page);
    this.getWorkspaces();
  }

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.getAllUsers(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getAllUsers(1, newPageSize);
    });
  };

  getAllUsers = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      // onSetLoader(true);
      const users = await axiosGet(
        `users?populate=assignNumbers,assignedWhatsappNumbers&page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (users?.status) {
        const updatedUsers = users?.data?.results.map((user) => ({
          ...user,
          assignedNumberLength: user.assignNumbers?.length || 0,
        }));
        this.setState({
          users: updatedUsers,
          totalSize: users?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('error while getting all users :', error);
      onSetLoader(false);
    }
  };

  deleteUser = async () => {
    const { page, user } = this.state;
    try {
      const response = await axiosDel(`/users/${user?.id}`);
      if (response?.status) {
        this.getAllUsers(page);
        this.setState({ deleteModal: false });
        toast.success(response?.message || 'user deleted successfully!', {
          position: 'top-right',
        });
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
    } catch (error) {
      console.error('error while adding user :', error);
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      showUserModal: !prevState.showUserModal,
    }));
  };

  onClickDelete = (users) => {
    this.setState({ user: users });
    this.setState({ deleteModal: true });
  };

  handleReassign = () => {
    const { onSetShowNumberModal } = this.props;
    onSetShowNumberModal();
  };

  handleUserClicks = () => {
    this.setState({ user: '', isEdit: false });
    this.toggle();
  };

  handleUserClick = (arg) => {
    const user = arg;

    this.setState({
      user: {
        id: user.id,
        name: user.name,
        phoneNumber: user.phoneNumber,
        email: user.email,
        uploadPermission: user.uploadPermission,
        status: user.status,
        gender: user.gender,
        workspaces: user.assignedWorkspaces,
        role: user.role,
      },
      isEdit: true,
    });

    this.toggle();
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.getAllUsers(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  getWorkspaces = async () => {
    const { onSetLoader } = this.props;
    try {
      // onSetLoader(true);
      const workspaces = await axiosGet('/workspace/organization');
      if (workspaces?.status) {
        this.setState({
          workspaceList: workspaces.data.results,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('error while getting workspaces:', error);
      onSetLoader(false);
    }
  };

  render() {
    // meta title
    document.title = 'User List';

    const {
      isEdit,
      deleteModal,
      users,
      user,
      showUserModal,
      workspaceList,
      page,
      userColumns,
      totalSize,
      selectedRows,
    } = this.state;

    return (
      <>
        <Number />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.deleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <UserForm
          showUserModal={showUserModal}
          toggle={this.toggle}
          user={user}
          getAllUsers={() => {
            this.getAllUsers(page);
          }}
          isEdit={isEdit}
          workspaceList={workspaceList}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="User" breadcrumbItem="Users List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end mb-3">
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleUserClicks}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Add New User
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={users}
                        columns={userColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

User.propTypes = {
  onSetShowNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetShowNumberModal: () => dispatch(setShowNumberModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
