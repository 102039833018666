import SHOW_SERVICES_MODAL from './actionsTypes';

const INIT_STATE = {
  showServicesFormModal: false,
};
const services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_SERVICES_MODAL:
      return {
        ...state,
        showServicesFormModal: !state.showServicesFormModal,
      };

    default:
      return state;
  }
};

export default services;
