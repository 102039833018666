import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isNumber, some } from 'lodash';
import {
  setBMessageContactGroups,
  setLoader,
  setBMessageStepTwoData,
} from '../../../../../store/actions';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import Label from '../../../../../components/Label';
import IconText from '../../../../../components/IconText';
import Card from '../../../../../components/Card';
import DataGrid from '../../../../../components/DataTable';
import { renderUserAvatar } from '../../../../../helpers/commonHelpers';
import { DATE_CONSTANTS } from '../../../../../constants/datetime.constant';
import { showToast } from '../../../../../services/toastService';
import { toastType } from '../../../../../constants/toast';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledSpan = styled.span`
  font-size: 1.1rem;
`;

const ViewCount = styled.div`
  cursor: pointer;
  color: ${({ countFounded }) => (countFounded ? '' : 'var(--blue-600)')};
`;
ViewCount.propTypes = {
  $countFounded: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

const RowPropType = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contactCount: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

const RenderedValuePropType = {
  renderedCellValue: PropTypes.string.isRequired,
};

function NumberOfAudienceCell({ row, onViewCount }) {
  const currentRowData = row?.original;
  return (
    <ViewCount
      onClick={() => {
        if (!isNumber(currentRowData?.contactCount)) {
          onViewCount(currentRowData?.id);
        }
      }}
      $countFounded={isNumber(currentRowData?.contactCount)}
    >
      {currentRowData?.contactCount ?? 'View count'}
    </ViewCount>
  );
}

NumberOfAudienceCell.propTypes = {
  ...RowPropType,
  onViewCount: PropTypes.func.isRequired,
};

function CreatedByCell({ renderedCellValue }) {
  return renderUserAvatar(renderedCellValue, 'me-3');
}

function CreatedAtCell({ renderedCellValue }) {
  const formattedDate = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
  const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
  return (
    <div>
      <IconText icon="bx bx-calendar" text={formattedDate} />
      <IconText icon="bx bx-time" text={formattedTime} />
    </div>
  );
}

CreatedByCell.propTypes = RenderedValuePropType;
CreatedAtCell.propTypes = RenderedValuePropType;

// NOTE: `BMessage`: Broadcast message
function Step2({ formCompletion, contactGroupID }) {
  const dispatch = useDispatch();
  const { step2 } = useSelector((state) => state.bulkMessage);
  const {
    selectedContactGroup, contactGroups, totalSize, page, size, searchValue,
  } = step2;

  const getSelectedRows = useMemo(() => {
    if (!selectedContactGroup?.id) return {};

    const selectedIndex = contactGroups.findIndex(
      (group) => group.id === selectedContactGroup.id,
    );

    return selectedIndex !== -1 ? { [selectedIndex]: true } : {};
  }, [selectedContactGroup, contactGroups]);

  const handleViewCountClick = async (contactGroupId) => {
    try {
      // dispatch(setLoader(true));
      const response = await contactGroupServices.getContactCount(contactGroupId);
      if (response?.status) {
        const contactGroupsArray = contactGroups.map((contactGroup) => (contactGroup.id === contactGroupId
          ? { ...contactGroup, contactCount: response?.data?.contactCount }
          : contactGroup));

        dispatch(setBMessageContactGroups({ contactGroups: contactGroupsArray }));
        if (selectedContactGroup?.id === contactGroupId) {
          dispatch(
            setBMessageStepTwoData({
              selectedContactGroup: {
                ...selectedContactGroup,
                contactCount: response?.data?.contactCount,
              },
            }),
          );
        }
      } else {
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({ content: error?.message || 'Oops! Something went wrong', type: toastType.error });
      console.error('error at handleGetAllContactGroups :', error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const contactListColumns = useMemo(() => [
    {
      header: 'Segment Name',
      accessorKey: 'name',
    },
    {
      header: 'Created By',
      accessorKey: 'assignedUserName',
      Cell: CreatedByCell,
    },
    {
      header: 'Number of Audience',
      accessorKey: 'contactIds',
      Cell: (props) => <NumberOfAudienceCell {...props} onViewCount={handleViewCountClick} />,
    },
    {
      header: 'Created At',
      accessorKey: 'createdAt',
      Cell: CreatedAtCell,
    },
  ], [handleViewCountClick]);

  const handleGetAllContactGroups = async () => {
    try {
      // dispatch(setLoader(true));
      const response = await contactGroupServices.getAllContactGroupsInPages(
        page,
        size,
        searchValue,
      );
      if (response?.status) {
        const selectedContactGroupExists = some(response?.data?.results, {
          id: selectedContactGroup?.id,
        });

        if (!selectedContactGroupExists) {
          dispatch(setBMessageStepTwoData({}));
          formCompletion(false);
        }
        dispatch(
          setBMessageContactGroups({
            contactGroups: response?.data?.results || [],
            totalSize: response?.data?.totalPages || 0,
          }),
        );
      } else {
        showToast({
          content: response?.message || 'Oops! Something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! Something went wrong',
        type: toastType.error,
      });
      console.error('error at handleGetAllContactGroups :', error);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleSearch = (searchQuery) => {
    dispatch(setBMessageContactGroups({ searchValue: searchQuery, page: 1 }));
  };

  const handlePageChange = (newPage) => {
    dispatch(setBMessageContactGroups({ page: newPage }));
  };

  const handleSizeChange = (newSize) => {
    dispatch(setBMessageContactGroups({ page: 1, size: newSize }));
  };

  const onContactGroupSelection = useCallback((selectedRows) => {
    const selectedGroup = selectedRows?.[0];

    if (selectedGroup) {
      dispatch(setBMessageStepTwoData({ selectedContactGroup: selectedGroup }));
      formCompletion(true);
    } else {
      dispatch(setBMessageStepTwoData({ selectedContactGroup: null }));
      formCompletion(false);
    }
  }, [dispatch, formCompletion]);
  // Effect to handle initial load and contactGroupId changes
  useEffect(() => {
    const updateSelection = async () => {
      const groups = contactGroups;
      const effectiveContactGroupId = contactGroupID || (groups[0]?.id);
      // Only proceed with selection if we have a contactGroupId
      if (effectiveContactGroupId) {
        const groupToSelect = groups.find((group) => group.id === effectiveContactGroupId);
        if (groupToSelect) {
          onContactGroupSelection([groupToSelect]);
        }
      }
    };

    updateSelection();
  }, [contactGroupID, contactGroups]);

  useEffect(() => {
    handleGetAllContactGroups();
  }, []);

  return (
    <StyledCard>
      <div className="mb-3">
        <Label className="form-label mb-3">
          <StyledSpan>Select Contact Group</StyledSpan>
        </Label>
        <DataGrid
          data={contactGroups}
          columns={contactListColumns}
          enableColumnResizing={false}
          enableColumnFilterModes
          enableEditing={false}
          enableMultiRowSelection={false}
          selectedRows={getSelectedRows}
          onRowSelectionChange={onContactGroupSelection}
          totalSize={totalSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handleSizeChange}
          initialPage={page - 1}
          initialPageSize={10}
          onSearch={handleSearch}
        />
      </div>
    </StyledCard>
  );
}

Step2.propTypes = {
  formCompletion: PropTypes.func,
  contactGroupID: PropTypes.string,
};

export default Step2;
