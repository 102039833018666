/* eslint-disable func-names */
import React, {
  useEffect, useContext, useState, useCallback,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import PropTypes from 'prop-types';
import { STEPS_NAME } from '../../../constants/onboarding.constant';
import { UserContext } from '../../UserProvider/UserProvider';
import IMAGES from '../../../helpers/images';
import {
  CHANNEL_CATEGORIES,
  CHANNEL_CONNECT_STATUS,
  CHANNEL_GROUPS,
  CHANNEL_TYPES,
  CHANNELS,
  CHANNELS_TITLE,
  CONNECT_STATUS,
} from '../../../constants/channels/channels.constant';
import { whatsappExchangeToken } from '../../../services/api/channels.service';
import { getPrivateCredentials, updateOrganization } from '../../../services/api/organization.service';
import { handleOnboardingStepCompletion } from '../../../utils/onboarding';
import {
  setLoader,
  setOnboardingData as setOnboardingDataAction,
  setShowOpenAiCredentialsModal,
  setShowTwilioCredentialsModal,
  setShowWhatsappCredentialsModal,
} from '../../../store/actions';
import { getChannels } from '../../../utils/channels';
import TwilioCredentials from './Modal/TwilioCredentials';
import { MODE } from '../../../constants/constants';
import WhatsappCredentials from './Modal/WhatsappCredentials';
import OpenAiCredentials from './Modal/OpenAiCredentials';
import CategorySection from './CategorySelection';
import { fetchAvailableWhatsappNumbers, updateWhatsappNumbersStatus } from '../../../services/api/whatsappNumber.service';
import { showToast } from '../../../services/toastService';
import { NUMBER_STATUS } from '../../../constants/status.constant';

function ChannelList({ isOnboarding }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [connectingWhatsapp, setConnectingWhatsapp] = useState(false);
  const [connectingFacebook] = useState(true);
  const [connectingInstagram] = useState(true);
  const [connectingWebchat] = useState(true);
  const [connectingTwilio] = useState(false);
  const [connectingPlivo] = useState(true);
  const [connectingOpenai] = useState(false);
  const [connectingDialogflow] = useState(true);
  const [credentials, setCredentials] = useState([]);
  const [whatsappNumbersFetched, setWhatsappNumbersFetched] = useState(false);
  const [wabaId] = useState(null); // State to store the WABA ID
  const wabaIdRef = useRef(wabaId);

  const fetchPrivateCredentials = useCallback(async () => {
    try {
      // dispatch(setLoader(true));
      const organizationId = userData?.organizationId;
      const response = await getPrivateCredentials(organizationId);
      if (response?.status) {
        setCredentials(response.data);
      } else {
        showToast({
          type: 'error',
          content: response?.message || 'Cannot get private credentials',
        });
      }
    } catch (error) {
      console.error('Error fetching private credentials:', error);
      showToast({
        type: 'error',
        content: 'An unexpected error occurred',
      });
    } finally {
      dispatch(setLoader(false));
    }
  }, [dispatch, userData]);

  const handleWhatsAppExchangeToken = async (code) => {
    const organizationId = userData?.organizationId;
    setConnectingWhatsapp(true);
    try {
      if (organizationId) {
        const response = await whatsappExchangeToken(organizationId, code);
        if (response?.status) {
          showToast({
            type: 'success',
            content: response?.message || 'Access Token set successfully!',
          });
          if (isOnboarding) {
            await handleOnboardingStepCompletion({
              stepName: STEPS_NAME.SETUP_CHANNELS,
              organizationId,
              history,
              setOnboardingData: (data) => dispatch(setOnboardingDataAction(data)),
            });
          }
          await fetchPrivateCredentials();
          return response;
        }
        showToast({
          type: 'error',
          content: response?.message || 'Failed to set Access token',
        });
        return null;
      }
      return null;
    } catch (error) {
      console.error('Error setting Access Token:', error);
      showToast({
        type: 'error',
        content: 'An unexpected error occurred',
      });
      return null;
    } finally {
      setConnectingWhatsapp(false);
    }
  };

  const launchWhatsAppSignup = () => {
    const messageHandler = (event) => {
      if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
        return;
      }

      try {
        const data = JSON.parse(event.data);

        if (data.type === 'WA_EMBEDDED_SIGNUP' && data?.data?.waba_id) {
          wabaIdRef.current = data.data.waba_id;
          console.info('Captured WABA ID:', wabaId.current);
        }
      } catch (parseError) {
        console.error('Error parsing message event:', parseError);
      }
    };

    window.addEventListener('message', messageHandler);
    window.fbq?.('trackCustom', 'WhatsAppOnboardingStart', {
      appId: process.env.REACT_APP_META_APP_ID,
      feature: 'whatsapp_embedded_signup',
    });

    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const { code } = response.authResponse;
          handleWhatsAppExchangeToken(code).then((tokenExchangeResponse) => {
            if (tokenExchangeResponse?.status && !whatsappNumbersFetched) {
              fetchAvailableWhatsappNumbers(wabaIdRef.current)
                .then((numbers) => {
                  console.info('Fetched Whatsapp numbers:', numbers);
                  setWhatsappNumbersFetched(true);
                })
                .catch((error) => {
                  console.error('Error fetching Whatsapp numbers:', error);
                });
            }
          });
        } else {
          // NOTE: User cancelled login or did not fully authorize.
        }
      },
      {
        config_id: process.env.REACT_APP_META_APP_CONFIGURATION_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: '',
          sessionInfoVersion: '3',
        },
      },
    );
  };

  const handleOpenTwilioModal = () => {
    dispatch(setShowTwilioCredentialsModal());
  };

  const handleDisconnectWhatsapp = async () => {
    const updateBody = {
      channels: [
        {
          type: CHANNEL_TYPES.WHATSAPP,
          status: CHANNEL_CONNECT_STATUS.DISCONNECTED,
          config: {
            metaSystemUserAccessToken: '',
          },
        },
      ],
    };
    try {
      const organizationId = userData?.organizationId;
      const response = await updateOrganization(organizationId, updateBody);
      if (response?.status) {
        fetchPrivateCredentials();
        await updateWhatsappNumbersStatus(NUMBER_STATUS.INACTIVE);
        showToast({
          type: 'success',
          content: response?.message || 'Whatsapp disconnected successfully!',
        });
      } else {
        showToast({
          type: 'error',
          content: response?.message || 'Oops! Something went wrong',
        });
      }
    } catch (error) {
      console.error('Error while disconnecting Whatsapp:', error);
      showToast({
        type: 'error',
        content: 'An unexpected error occurred',
      });
    }
  };

  const handleWhatsappConnection = (isWhatsappConnected) => {
    if (process.env.REACT_APP_MODE === MODE.CLIENT) {
      dispatch(setShowWhatsappCredentialsModal());
    } else if (isWhatsappConnected) {
      handleDisconnectWhatsapp();
    } else {
      launchWhatsAppSignup();
    }
  };

  const handleFacebookConnection = () => {};

  const handleInstagramConnection = () => {};

  const handleWebchatConnection = () => {};

  const handleOpenPlivoModal = () => {};

  const handleOpenaiModal = () => {
    dispatch(setShowOpenAiCredentialsModal());
  };

  const handleDialogflowConnection = () => {};

  const loadFacebookSDK = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_META_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v22.0',
        autoLogAppEvents: true,
      });
    };

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };

  const {
    twilio, whatsapp, facebook, instagram, webchat, openai, dialogflow, plivo,
  } = getChannels(credentials);
  const isWhatsAppConnected = whatsapp?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isFacebookConnected = facebook?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isInstagramConnected = instagram?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isTwilioConnected = twilio?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isPlivoConnected = plivo?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isOpenaiConnected = openai?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isDialogflowConnected = dialogflow?.status === CHANNEL_CONNECT_STATUS.CONNECTED;
  const isWebchatConnected = webchat?.status === CHANNEL_CONNECT_STATUS.CONNECTED;

  const getWhatsappButtonText = () => {
    if (connectingWhatsapp) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} />;
    }
    if (isWhatsAppConnected) {
      return process.env.REACT_APP_MODE === MODE.CLIENT
        ? CONNECT_STATUS.CONFIGURE
        : CONNECT_STATUS.DISCONNECT;
    }
    return CONNECT_STATUS.CONNECT;
  };

  const getFacebookButtonText = () => {
    if (isFacebookConnected) {
      return process.env.REACT_APP_MODE === MODE.CLIENT
        ? CONNECT_STATUS.CONFIGURE
        : CONNECT_STATUS.DISCONNECT;
    }
    return CONNECT_STATUS.COMING_SOON;
  };

  const getInstagramButtonText = () => {
    if (isInstagramConnected) {
      return process.env.REACT_APP_MODE === MODE.CLIENT
        ? CONNECT_STATUS.CONFIGURE
        : CONNECT_STATUS.DISCONNECT;
    }
    return CONNECT_STATUS.COMING_SOON;
  };
  const getWebchatButtonText = () => {
    if (isWebchatConnected) {
      return process.env.REACT_APP_MODE === MODE.CLIENT
        ? CONNECT_STATUS.CONFIGURE
        : CONNECT_STATUS.DISCONNECT;
    }
    return CONNECT_STATUS.COMING_SOON;
  };

  const getTwilioButtonText = () => {
    if (connectingTwilio) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} />;
    }
    return isTwilioConnected
      ? CONNECT_STATUS.CONFIGURE
      : CONNECT_STATUS.CONNECT;
  };

  const getPlivoButtonText = () => (isPlivoConnected
    ? CONNECT_STATUS.CONFIGURE
    : CONNECT_STATUS.COMING_SOON);

  const getOpenaiButtonText = () => {
    if (connectingOpenai) {
      return <ProgressSpinner style={{ width: '20px', height: '20px' }} />;
    }
    return isOpenaiConnected
      ? CONNECT_STATUS.CONFIGURE
      : CONNECT_STATUS.CONNECT;
  };
  useEffect(() => {
    document.title = CHANNELS_TITLE;
    loadFacebookSDK();
    fetchPrivateCredentials();
  }, []);

  const getDialogflowButtonText = () => (isDialogflowConnected ? CONNECT_STATUS.CONFIGURE : CONNECT_STATUS.COMING_SOON);

  const channelConfig = {
    // Social Media Channels
    [CHANNELS.FACEBOOK]: {
      name: 'Facebook',
      icon: 'bx bxl-facebook text-primary',
      type: 'icon',
      bgClass: 'bg-primary-subtle',
      buttonClass: 'p-button-primary',
      isConnected: isFacebookConnected,
      handleConnection: () => handleFacebookConnection(isFacebookConnected),
      getButtonText: getFacebookButtonText,
      disabled: connectingFacebook,
    },
    [CHANNELS.INSTAGRAM]: {
      name: 'Instagram',
      icon: 'bx bxl-instagram text-danger',
      type: 'icon',
      bgClass: 'bg-danger-subtle',
      buttonClass: 'p-button-danger',
      isConnected: isInstagramConnected,
      handleConnection: () => handleInstagramConnection(isInstagramConnected),
      getButtonText: getInstagramButtonText,
      disabled: connectingInstagram,
    },
    [CHANNELS.WHATSAPP]: {
      name: 'WhatsApp',
      icon: 'bx bxl-whatsapp text-success',
      type: 'icon',
      bgClass: 'bg-success-subtle',
      buttonClass: 'p-button-success',
      isConnected: isWhatsAppConnected,
      handleConnection: () => handleWhatsappConnection(isWhatsAppConnected),
      getButtonText: getWhatsappButtonText,
      disabled: connectingWhatsapp,
    },
    [CHANNELS.WEBCHAT]: {
      name: 'Webchat',
      icon: IMAGES.webchat,
      type: 'image',
      bgClass: 'bg-warning-subtle',
      buttonClass: 'p-button-warning',
      isConnected: isWebchatConnected,
      handleConnection: () => handleWebchatConnection(isWebchatConnected),
      getButtonText: getWebchatButtonText,
      disabled: connectingWebchat,
    },
    // Telephony Channels
    [CHANNELS.TWILIO]: {
      name: 'Twilio',
      icon: IMAGES.twilio,
      type: 'image',
      bgClass: 'bg-danger-subtle',
      buttonClass: 'p-button-danger',
      isConnected: isTwilioConnected,
      handleConnection: () => handleOpenTwilioModal(),
      getButtonText: getTwilioButtonText,
      disabled: connectingTwilio,
    },
    [CHANNELS.PLIVO]: {
      name: 'Plivo',
      icon: IMAGES.plivo,
      type: 'image',
      bgClass: 'bg-success-subtle',
      buttonClass: 'p-button-success',
      isConnected: isPlivoConnected,
      handleConnection: () => handleOpenPlivoModal(),
      getButtonText: getPlivoButtonText,
      disabled: connectingPlivo,
    },
    // Generative AI Channels
    [CHANNELS.OPENAI]: {
      name: 'OpenAI',
      icon: IMAGES.openai,
      type: 'image',
      bgClass: 'bg-dark-subtle',
      buttonClass: 'p-button-rounded',
      isConnected: isOpenaiConnected,
      handleConnection: () => handleOpenaiModal(),
      getButtonText: getOpenaiButtonText,
      disabled: connectingOpenai,
      buttonStyle: {
        backgroundColor: '#000',
        color: '#fff',
        borderColor: '#000',
      },
    },
    [CHANNELS.DIALOGFLOW]: {
      name: 'Dialogflow',
      icon: IMAGES.dialoagFlow,
      type: 'image',
      bgClass: 'bg-warning-subtle',
      buttonClass: 'p-button-warning',
      isConnected: isDialogflowConnected,
      handleConnection: () => handleDialogflowConnection(isDialogflowConnected),
      getButtonText: getDialogflowButtonText,
      disabled: connectingDialogflow,
    },
  };

  return (
    <>
      <TwilioCredentials
        getPrivateCredentials={fetchPrivateCredentials}
        privateCreds={credentials}
      />
      <WhatsappCredentials
        getPrivateCredentials={fetchPrivateCredentials}
        privateCreds={credentials}
      />
      <OpenAiCredentials
        getPrivateCredentials={fetchPrivateCredentials}
        privateCreds={credentials}
      />

      {Object.entries(CHANNEL_GROUPS).map(([categoryKey, channels]) => (
        <CategorySection
          key={categoryKey}
          category={CHANNEL_CATEGORIES[categoryKey]}
          channels={channels}
          channelConfig={channelConfig}
        />
      ))}
    </>
  );
}

ChannelList.propTypes = {
  isOnboarding: PropTypes.bool.isRequired,
};

export default ChannelList;
