import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import { tabs } from '../../constants/tabs';
import { PAGE, SIZE_PER_PAGE } from '../../constants/constants';
import Pie from '../../components/Charts/PieChart';
import Bar from '../../components/Charts/BarChart';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { axiosGet, axiosPost } from '../../services/http.service';
import FilterComponent from './FilterComponent';
import { setLoader } from '../../store/actions';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import { capitalizeFirstLetter } from '../../utils/general';
import DataGrid from '../../components/DataTable';

class MessageBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCostData: {},
      activeTab: tabs.TEXT,
      filteredCampaignsData: [],
      filteredMessageCampaignData: [],
      page: { header: PAGE, whatsapp: PAGE },
      loading: false,
      filters: {
        startDate: moment().subtract(30, DATE_CONSTANTS.UNITS.DAYS).toDate(),
        endDate: moment().toDate(),
        campaigns: [],
        user: '',
      },
      size: { header: SIZE_PER_PAGE, whatsapp: SIZE_PER_PAGE },
      selectedRows: [],
    };
  }

  componentDidMount() {
    this.componentDidMountAsync();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, activeTab } = this.state;

    if (prevState.activeTab !== activeTab || prevState.page[activeTab] !== page[activeTab]) {
      this.fetchCampaignData();
      this.fetchCampaignMessageData();
    }
  }

  componentDidMountAsync = async () => {
    const { activeTab, page } = this.state;
    await Promise.allSettled([
      this.fetchCampaignMessageData(page[activeTab]),
      this.fetchCampaignData(page[activeTab]),
      this.getTotalCost(),
    ]);
  };

  fetchCampaignData = async () => {
    const {
      activeTab, size, page, filters,
    } = this.state;
    const { onSetLoader } = this.props;
    this.setState({ loading: true });
    // onSetLoader(true);
    const filter = {
      dateRange: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      userId: filters?.selectedUser?.value,
      campaignIds: filters?.selectedCampaigns?.map((c) => c.value),
    };
    const channel = activeTab;
    try {
      const response = await axiosPost(
        `campaign/billing/campaigns?page=${page[activeTab]}&limit=${size[activeTab]}`,
        { filter, channel },
      );
      if (response.status) {
        this.setState({
          filteredCampaignsData: response.data.results,
          totalSize: response.data.totalResults,
          loading: false,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('Error fetching campaign data:', error);
      this.setState({ loading: false });
      onSetLoader(false);
    }
  };

  fetchCampaignMessageData = async () => {
    const {
      page, size, activeTab, filters,
    } = this.state;
    const { onSetLoader } = this.props;
    const filter = {
      dateRange: {
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      userId: filters?.selectedUser?.value,
      campaignIds: filters?.selectedCampaigns?.map((c) => c.value),
    };
    const channel = activeTab;
    // onSetLoader(true);
    try {
      const response = await axiosPost(
        `/campaign/billing/campaigns-messages?page=${page[activeTab]}&limit=${size[activeTab]}`,
        { filter, channel },
      );
      if (response?.status) {
        this.setState({
          filteredMessageCampaignData: response.data.results,
          totalSize: response.data.totalResults,
          loading: false,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      console.error('Error at fetchCampaignMessageData:', error);
    }
  };

  getTotalCost = async () => {
    try {
      const response = await axiosGet('/campaign/billing/messages/total-cost');

      this.setState({ totalCostData: response.data });
    } catch (error) {
      console.error('error at getTotalCost:', error);
    }
  };

  toggleTab = (tab) => {
    const { activeTab, page } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        filters: {
          campaigns: [],
          user: null,
          startDate: moment()
            .subtract(30, DATE_CONSTANTS.UNITS.DAYS)
            .startOf(DATE_CONSTANTS.UNITS.DAY)
            .toDate(),
          endDate: moment().endOf(DATE_CONSTANTS.UNITS.DAY).toDate(),
        },
        page: { ...page, [tab]: 1 },
        filteredMessageCampaignData: [],
      });
    }
  };

  getBarChartData = () => {
    const { filteredCampaignsData } = this.state;
    const dataForTab = filteredCampaignsData || [];

    const xAxisData = dataForTab.map((campaign) => campaign.name);

    const data = [
      dataForTab.map((campaign) => campaign.failedCost || 0),
      dataForTab.map((campaign) => campaign.deliveredCost || 0),
      dataForTab.map((campaign) => campaign.queuedCost || 0),
      dataForTab.map((campaign) => campaign.sentCost || 0),
    ];

    return {
      xAxisData,
      seriesNames: ['Failed', 'Delivered', 'Queued', 'Sent'],
      data,
      showLine: true,
    };
  };

  handlePageClick = (selectedPage) => {
    const { activeTab } = this.state;
    this.setState(
      (prevState) => ({
        page: {
          ...prevState.page,
          [activeTab]: selectedPage,
        },
      }),
      () => {
        this.fetchCampaignData(selectedPage);
        this.fetchCampaignMessageData(selectedPage);
      },
    );
  };

  handleLimitChange = (newPageSize) => {
    const selectedSize = newPageSize;
    const { activeTab, page } = this.state;

    this.setState(
      (prevState) => ({
        size: {
          ...prevState.size,
          [activeTab]: selectedSize,
        },
        page: {
          ...prevState.page,
          [activeTab]: 1,
        },
      }),
      () => {
        this.fetchCampaignData(page[activeTab]);
        this.fetchCampaignMessageData(page[activeTab]);
      },
    );
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  renderContent = () => {
    const {
      activeTab, filteredMessageCampaignData, loading, totalSize, selectedRows,
    } = this.state;
    const barChartData = this.getBarChartData();
    const columns = [
      {
        accessorKey: 'createdAt',
        header: 'Date',
        Cell: ({ renderedCellValue }) => {
          const formattedDate = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.MM_DD_YYYY);
          const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
          return (
            <div>
              {formattedDate}
              <br />
              {formattedTime}
            </div>
          );
        },
      },
      {
        accessorKey: 'from',
        header: 'From',
        Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
      },
      {
        accessorKey: 'to',
        header: 'To',
        Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
      },
      { accessorKey: 'campaignName', header: 'Campaign' },
      { accessorKey: 'messageSid', header: 'Message SID' },
      {
        accessorKey: 'cost',
        header: 'Cost ($)',
        Cell: ({ renderedCellValue }) => (
          <span>{`$${!_.isEmpty(renderedCellValue) ? renderedCellValue : 0}`}</span>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col lg="12">
            <Card className="shadow-sm border border-light-subtle">
              <CardBody className="e-chart">
                <div id={`bar-graph-${activeTab}`}>
                  <Bar
                    {...barChartData}
                    title={`${capitalizeFirstLetter(activeTab)} Campaign Cost Insights`}
                    yAxisName="Cost ($)"
                    loading={loading}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <div className="mt-3">
                  <DataGrid
                    data={filteredMessageCampaignData}
                    columns={columns}
                    enableColumnResizing={false}
                    enableColumnFilterModes
                    enableEditing={false}
                    enableGlobalFilter={false}
                    selectedRows={selectedRows}
                    onRowSelectionChange={this.handleRowSelection}
                    totalSize={totalSize}
                    onPageChange={this.handlePageClick}
                    onPageSizeChange={this.handleLimitChange}
                    initialPage={0}
                    initialPageSize={10}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  getPieChartData = () => {
    const { totalCostData } = this.state;
    const smsTotalCost = totalCostData.totalSmsCost;
    const whatsappTotalCost = totalCostData.totalWhatsappCost;
    return [
      { name: 'Text', value: smsTotalCost },
      { name: 'WhatsApp', value: whatsappTotalCost },
    ];
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, () => {
      this.fetchCampaignData();
      this.fetchCampaignMessageData();
    });
  };

  render() {
    const { activeTab, filters } = this.state;
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Billing" breadcrumbItem="Message Billing" />
          <Row>
            <Col>
              <Card className="filter-card shadow-sm border border-light-subtle">
                <CardBody className="filter-card-body">
                  <FilterComponent
                    activeTab={activeTab}
                    showCampaigns
                    onApplyFilter={this.handleFilterChange}
                    initialFilters={filters}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="shadow-sm border border-light-subtle">
                <CardBody>
                  <Pie
                    data={this.getPieChartData()}
                    title="Message Cost Breakdown"
                    height={400}
                    name="Cost Breakdown"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === tabs.TEXT })}
                    onClick={() => this.toggleTab(tabs.TEXT)}
                  >
                    <i className="bx bx-message-alt-dots font-size-18 me-1" />
                    Text
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === tabs.WHATSAPP,
                    })}
                    onClick={() => this.toggleTab(tabs.WHATSAPP)}
                  >
                    <i className="bx bxl-whatsapp font-size-18 me-1" />
                    WhatsApp
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId={tabs.TEXT}>{this.renderContent()}</TabPane>
                <TabPane tabId={tabs.WHATSAPP}>{this.renderContent()}</TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
MessageBilling.propTypes = {
  onSetLoader: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (loading) => dispatch(setLoader(loading)),
});
export default connect(null, mapDispatchToProps)(MessageBilling);
