import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
// import PropTypes from 'prop-types';
import classnames from 'classnames';
import Services from '../Services/Services';
import Channels from '../Channels/Channels';
import Templates from '../Campaign/MessageTemplate/Templates';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Numbers from '../PhoneNumber/Numbers';
import BlockedNumbers from '../Blocked Numbers/BlockedNumbers';
import QuickMessage from '../Quick Message/QuickMessage';
import ContactGroups from '../Contacts/Contact Groups/ContactGroups';
import SenderGroup from '../Sender Group/SenderGroup';
import AlertNotification from '../AlertsManagement/AlertNotification';
import { tabs } from '../../constants/tabs';
import Teams from '../Teams/Team';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { capitalizeFirstLetter } from '../../utils/general';
import { ROLES } from '../../constants/users/role.constant';

class CommunicationSetting extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: tabs.CHANNELS,
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('activeTab');
    if (tab) {
      this.setState({ activeTab: tab });
    }
  }

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { activeTab } = this.state; // Destructure activeTab here
    const { userData } = this.context;
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={capitalizeFirstLetter(activeTab)}
            breadcrumbItem="Communication Settings"
          />
          <Row>
            <Col md={2} className="bg-body-tertiary setting-sidebar">
              <div className="p-4">
                <Nav role="tablist" vertical pills>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.CHANNELS })}
                      onClick={() => this.toggle(tabs.CHANNELS)}
                    >
                      <i className="mdi mdi-connection me-1" />
                      Channels
                    </NavLink>
                  </NavItem>
                  {(userData.role === ROLES.ADMIN || userData.role === ROLES.MANAGER) && (
                    <NavItem className="mb-2">
                      <NavLink
                        className={classnames({ active: activeTab === tabs.TEAMS })}
                        onClick={() => this.toggle(tabs.TEAMS)}
                      >
                        <i className="mdi mdi-account-multiple me-1" />
                        Teams
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.PHONE_NUMBERS })}
                      onClick={() => this.toggle(tabs.PHONE_NUMBERS)}
                    >
                      <i className="bx bx-hash me-1" />
                      Phone Numbers
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.DND })}
                      onClick={() => this.toggle(tabs.DND)}
                    >
                      <i className="bx bx-block me-1" />
                      DND
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.CANNED_MESSAGES })}
                      onClick={() => this.toggle(tabs.CANNED_MESSAGES)}
                    >
                      <i className="bx bx-message-alt-check me-1" />
                      Canned Messages
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.CONTACT_GROUPS })}
                      onClick={() => this.toggle(tabs.CONTACT_GROUPS)}
                    >
                      <i className="bx bxs-contact me-1" />
                      Contact Groups
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.SENDER_GROUPS })}
                      onClick={() => this.toggle(tabs.SENDER_GROUPS)}
                    >
                      <i className="bx bx-group me-1" />
                      Sender Groups
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.SERVICES })}
                      onClick={() => this.toggle(tabs.SERVICES)}
                    >
                      <i className="bx bx-briefcase me-1" />
                      Services
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.ALERTS })}
                      onClick={() => this.toggle(tabs.ALERTS)}
                    >
                      <i className="bx bx-bell me-1" />
                      Alert
                    </NavLink>
                  </NavItem>
                  <NavItem className="mb-2">
                    <NavLink
                      className={classnames({ active: activeTab === tabs.INTEGRATION })}
                      onClick={() => this.toggle(tabs.INTEGRATION)}
                    >
                      <i className="bx bxs-plug me-1" />
                      Integration
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <Col md={10}>
              <Card className="border border-0 shadow-sm">
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={tabs.CHANNELS}>
                      {activeTab === tabs.CHANNELS && <Channels />}
                    </TabPane>
                    <TabPane tabId={tabs.TEAMS}>{activeTab === tabs.TEAMS && <Teams />}</TabPane>
                    <TabPane tabId={tabs.MESSAGE_TEMPLATE}>
                      {activeTab === tabs.MESSAGE_TEMPLATE && <Templates />}
                    </TabPane>
                    <TabPane tabId={tabs.PHONE_NUMBERS}>
                      {activeTab === tabs.PHONE_NUMBERS && <Numbers />}
                    </TabPane>
                    <TabPane tabId={tabs.DND}>
                      {activeTab === tabs.DND && <BlockedNumbers />}
                    </TabPane>
                    <TabPane tabId={tabs.CANNED_MESSAGES}>
                      {activeTab === tabs.CANNED_MESSAGES && <QuickMessage />}
                    </TabPane>
                    <TabPane tabId={tabs.CONTACT_GROUPS}>
                      {activeTab === tabs.CONTACT_GROUPS && <ContactGroups />}
                    </TabPane>
                    <TabPane tabId={tabs.SENDER_GROUPS}>
                      {activeTab === tabs.SENDER_GROUPS && <SenderGroup />}
                    </TabPane>
                    <TabPane tabId={tabs.SERVICES}>
                      {activeTab === tabs.SERVICES && <Services />}
                    </TabPane>
                    <TabPane tabId={tabs.ALERTS}>
                      {activeTab === tabs.ALERTS && <AlertNotification />}
                    </TabPane>
                    <TabPane tabId={tabs.INTEGRATION}>
                      <h4 className="mb-2 font-size-17">Integration</h4>
                      <p>Content for Integration tab.</p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default CommunicationSetting;
