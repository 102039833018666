/* eslint-disable no-shadow */
import {
  ErrorMessage,
  Field, Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { axiosPut, axiosPost } from '../../../services/http.service';
import ModalLoader from '../../../components/Common/ModalLoader';
import { setShowServicesModal } from '../../../store/services/actions';
import { showToast } from '../../../services/toastService';
import { toastType } from '../../../constants/toast';

function ServicesForm({
  isEdit, showServicesFormModal, service, className, onSetShowServicesModal, getAllServices,
}) {
  const [showModalLoader, setShowModalLoader] = useState(false);

  const toggle = () => {
    onSetShowServicesModal();
  };

  const handleAddService = async (data) => {
    try {
      setShowModalLoader(true);
      const response = await axiosPost('services', data);
      if (response?.status) {
        showToast({
          content: response?.message || 'Service created successfully!',
          type: toastType.success,
        });
        getAllServices();
        toggle();
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! something went wrong',
        type: toastType.error,
      });
      console.error('Error while adding service:', error);
    } finally {
      setShowModalLoader(false);
    }
  };

  const handleEditService = async (data) => {
    try {
      setShowModalLoader(true);
      const response = await axiosPut(`services/${service?.id}`, data);
      if (response?.status) {
        showToast({
          content: response?.message || 'Service updated successfully!',
          type: toastType.success,
        });
        getAllServices();
        toggle();
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! something went wrong',
        type: toastType.error,
      });
      console.error('Error while updating service:', error);
    } finally {
      setShowModalLoader(false);
    }
  };
  const onSubmit = async (values) => {
    if (isEdit) {
      await handleEditService(values);
    } else {
      await handleAddService(values);
    }
  };

  const initialValues = {
    name: service?.name || '',
    price: service?.price || '',
    duration: service?.duration || '',
    timeBuffer: service?.timeBuffer || '',
    description: service?.description || '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter service name'),
    price: Yup.number().required('Please enter the price').positive().min(0),
    duration: Yup.number().required('Please enter the duration').positive().min(0),
    timeBuffer: Yup.number().positive().optional().min(0),
    description: Yup.string().optional(),
  });

  return (
    <Modal isOpen={showServicesFormModal} className={className} size="lg">
      {showModalLoader && <ModalLoader />}
      <ModalHeader toggle={toggle} tag="h4">
        {isEdit ? 'Edit Service' : 'Add Service'}
      </ModalHeader>
      <ModalBody>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Row>
                <Col xs="12" md="6" className="mb-3">
                  <Label className="form-label">
                    Service Name
                    <span className="text-danger">*</span>
                  </Label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Service Name"
                    className={`form-control${errors.name && touched.name ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="name" component="div" className="invalid-feedback" />
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Label className="form-label">
                    Price
                    <span className="text-danger">*</span>
                  </Label>
                  <Field
                    name="price"
                    type="number"
                    min="0"
                    placeholder="Price"
                    className={`form-control${errors.price && touched.price ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="price" component="div" className="invalid-feedback" />
                </Col>
              </Row>
              <Row>
                <Col xs="12" md="6" className="mb-3">
                  <Label className="form-label">
                    Duration
                    <span className="text-danger">*</span>
                  </Label>
                  <Field
                    name="duration"
                    type="number"
                    min="0"
                    placeholder="In Minutes"
                    className={`form-control${errors.duration && touched.duration ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="duration" component="div" className="invalid-feedback" />
                </Col>
                <Col xs="12" md="6" className="mb-3">
                  <Label className="form-label">Time Buffer</Label>
                  <Field
                    name="timeBuffer"
                    type="number"
                    min="0"
                    placeholder="In Minutes"
                    className={`form-control${errors.timeBuffer && touched.timeBuffer ? ' is-invalid' : ''}`}
                  />
                  <ErrorMessage name="timeBuffer" component="div" className="invalid-feedback" />
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Label className="form-label">Description</Label>
                  <Field
                    name="description"
                    as="textarea"
                    className="form-control"
                    placeholder="Description"
                    rows="3"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="text-end mt-3">
                  <button type="submit" className="btn btn-success">Save</button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

ServicesForm.propTypes = {
  className: PropTypes.any,
  onSetShowServicesModal: PropTypes.func,
  isEdit: PropTypes.bool,
  showServicesFormModal: PropTypes.bool,
  service: PropTypes.object,
  getAllServices: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowServicesModal: () => dispatch(setShowServicesModal()),
});

const mapStateToProps = ({ services }) => ({
  showServicesFormModal: services.showServicesFormModal,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ServicesForm));
