import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  Badge, Button, Card, CardBody, Col, Container, Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { CHANNEL_TYPES } from '../../constants/channels/channels.constant';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import DeleteModal from '../../components/Common/DeleteModal';
import { axiosDel, axiosGet } from '../../services/http.service';
import { setShowNumberModal, setShowReassignNumberModal } from '../../store/phone-number/actions';
import { UserContext } from '../../components/UserProvider/UserProvider';
import { setLoader } from '../../store/actions';
import Number from './Modal/Number';
import ReassignNumber from './Modal/ReassignNumber';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import { ROLES } from '../../constants/users/role.constant';
import DataGrid from '../../components/DataTable';

class PhoneNumber extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      numbers: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      number: '',
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      phoneNumberColumns: [
        {
          header: 'No',
          accessorKey: 'id',
          Cell: ({ row }) => {
            const index = row?.id;
            return <span className="mb-1">{parseInt(index, 10) + 1}</span>;
          },
        },
        {
          header: 'Phone Number',
          accessorKey: 'phoneNumber',
          Cell: ({ renderedCellValue, row }) => {
            const originalRow = row?.original;
            return (
              <div>
                <div className="fw-medium">{originalRow.phoneAlias}</div>
                <PhoneNumberWithFlag phoneNumber={renderedCellValue} />
              </div>
            );
          },
        },
        {
          header: 'Type',
          accessorKey: 'numberType',
          Cell: ({ renderedCellValue }) => (
            <Badge color="info" className="ms-2" pill>
              {renderedCellValue}
            </Badge>
          ),
        },
        {
          header: 'Assigned User',
          accessorKey: 'assignedUserIds',
          Cell: ({ row }) => {
            const originalRow = row?.original;
            return (
              <div>
                {originalRow?.assignedUserIds?.map((assignedUser, index) => (
                  <Badge
                    key={`${assignedUser?.id}-${index + 1}`}
                    color="info"
                    className="ms-2"
                    pill
                  >
                    {assignedUser?.name}
                  </Badge>
                ))}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page, phoneNumberColumns } = this.state;
    this.handleGetNumbers(page);
    const { userData } = this.context;

    if (userData?.role === ROLES.ADMIN) {
      this.setState({
        phoneNumberColumns: [
          ...phoneNumberColumns,
          {
            accessorKey: 'menu',
            header: 'Actions',
            enableSorting: false,
            Cell: ({ row }) => {
              const numberInfo = row?.original;
              return (
                <div className="d-flex gap-3">
                  <Link to="#">
                    <Badge color="success-subtle" className="text-success">
                      <i
                        className="mdi mdi-redo-variant font-size-18 text-success"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="re-assign number"
                        onClick={() => this.handleUserClick(numberInfo)}
                      />
                    </Badge>
                  </Link>
                  <Link to="#">
                    <Badge color="danger-subtle" className="text-danger">
                      <i
                        className="mdi mdi-clipboard-arrow-right-outline font-size-18 text-danger"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="release number"
                        onClick={() => this.onClickDelete(numberInfo)}
                      />
                    </Badge>
                  </Link>
                </div>
              );
            },
          },
        ],
      });
    }
  }

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetNumbers(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetNumbers(1, newPageSize);
    });
  };

  toggle = () => {
    const { onSetShowNumberModal } = this.props;
    onSetShowNumberModal();
  };

  toggleReassignNumberModal = () => {
    const { onSetShowReassignNumberModal } = this.props;
    onSetShowReassignNumberModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = (numberInfo) => {
    this.setState({ number: numberInfo, deleteModal: true });
  };

  // handles when user clicks buy number btn
  handleUserClicks = () => {
    this.setState({ number: '' });
    this.toggle();
  };

  // handles when user tries to re-assign a number to another user
  handleUserClick = (numberInfo) => {
    this.setState({
      number: numberInfo,
    });

    this.toggleReassignNumberModal();
  };

  // to get list of all twilio number purchased
  handleGetNumbers = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      // onSetLoader(true);
      const numbers = await axiosGet(
        `numbers?populate=assignedUserIds&page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (numbers?.status) {
        this.setState({
          numbers: numbers?.data?.results,
          totalSize: numbers?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting twilio numbers :', error);
    }
  };

  // to release a twilio number
  handleReleaseNumber = async () => {
    const { onSetLoader } = this.props;
    try {
      // onSetLoader(true);
      const { number, page } = this.state;
      const response = await axiosDel(`numbers/${number?.phoneSid}`);
      if (response?.status) {
        onSetLoader(false);
        toast.success(response?.message || 'Twilio Number Released', {
          position: 'top-right',
        });
        this.handleGetNumbers(page);
      } else {
        onSetLoader(false);
        toast.error(response?.message || 'Oops! Something went wrong', {
          position: 'top-right',
        });
      }
      this.toggleDeleteModal();
    } catch (error) {
      onSetLoader(false);
      console.error('error while releasing a twilio number :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetNumbers(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'Phone Numbers';

    const { userData } = this.context;
    const { activeTab } = this.props;
    const {
      deleteModal, numbers, number, page, phoneNumberColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          buttonText="Yes, release it!"
          onDeleteClick={() => this.handleReleaseNumber()}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Number
          getAllNumbers={() => {
            this.handleGetNumbers(page);
          }}
          activeTab={activeTab}
        />
        <ReassignNumber
          channeltype={CHANNEL_TYPES.TEXT}
          numberInfo={number}
          getAllNumbers={() => {
            this.handleGetNumbers(page);
          }}
        />
        <div className="page-content p-0">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      {userData?.role === ROLES.ADMIN ? (
                        <div className="text-sm-end mb-3">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            onClick={this.handleUserClicks}
                          >
                            <i className="mdi mdi-plus-circle-outline me-1" />
                            Buy Number
                          </Button>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>

                    <div className="mt-3">
                      <DataGrid
                        data={numbers}
                        columns={phoneNumberColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

PhoneNumber.propTypes = {
  onSetShowNumberModal: PropTypes.func,
  onSetShowReassignNumberModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  activeTab: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = ({ phoneNumber }) => ({
  modal: phoneNumber.showNumberModal,
  showReassignNumberModal: phoneNumber.showReassignNumberModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetShowNumberModal: () => dispatch(setShowNumberModal()),
  onSetShowReassignNumberModal: () => dispatch(setShowReassignNumberModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PhoneNumber));
