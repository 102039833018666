import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as PrimeReactDropdown } from 'primereact/dropdown';
import styled, { createGlobalStyle } from 'styled-components';

// Global styles to ensure dropdown styling works
const GlobalDropdownStyles = createGlobalStyle`
  .p-dropdown-panel {
    z-index: 50000 !important;
  }
  // .p-dropdown-panel .p-dropdown-items-wrapper.p-virtualscroller .p-dropdown-item{
  //     padding: 2rem 0.5rem;  
  //   }

.p-dropdown-panel .p-dropdown-items-wrapper .p-dropdown-item{
    padding: 0.6rem;  
  }
`;

const StyledDropdown = styled(PrimeReactDropdown)`
  .p-dropdown {
    width: 100%;
    min-width: 200px;
  }

  .p-dropdown-label {
    padding: 0.5rem 0.75rem;
  }

  .p-dropdown-trigger {
    padding: 0 0.75rem;
  }
`;

function Dropdown({
  value = null,
  options,
  onChange,
  className = '',
  optionLabel,
  optionNumber,
  placeholder = 'Select an option',
  dropdownIcon = 'pi pi-chevron-down',
  valueTemplate,
  itemTemplate,
  ...rest
}) {
  return (
    <>
      <GlobalDropdownStyles />
      <StyledDropdown
        value={value}
        options={options}
        onChange={onChange}
        className={className}
        optionLabel={optionLabel}
        placeholder={placeholder}
        dropdownIcon={dropdownIcon}
        itemTemplate={itemTemplate}
        valueTemplate={valueTemplate}
        {...rest}
      />
    </>
  );
}

Dropdown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  itemTemplate: PropTypes.func,
  valueTemplate: PropTypes.func,
  className: PropTypes.string,
  optionLabel: PropTypes.string,
  optionNumber: PropTypes.string,
  placeholder: PropTypes.string,
  dropdownIcon: PropTypes.string,
};

export default Dropdown;
