/* eslint-disable import/prefer-default-export */
export const tabs = {
  TEXT: 'text',
  WHATSAPP: 'whatsapp',
  DELIVERY_OVERVIEW: 'delivery Overview',
  REPLY_ANALYSIS: 'reply Analysis',
  DISTRIBUTION_BY_TAGS: 'distribution By Tags',
  BULK: 'bulk',
  SINGLE: 'single',
  UPLOAD_FILE: 'upload File',
  SPREADSHEET_DETAILS: 'spreadsheet Details',
  CONFIRM_DETAILS: 'confirm Details',
  VIEW: 'view',
  EDIT: 'edit',
  PROFILE: 'profile',
  NOTES: 'notes',
  TIMELINE: 'timeline',
  ALL: 'all',
  READ: 'read',
  UNREAD: 'unread',
  CHANNELS: 'channels',
  TEAMS: 'teams',
  MESSAGE_TEMPLATE: 'message Template',
  PHONE_NUMBERS: 'phone Numbers',
  DND: 'dnd',
  CANNED_MESSAGES: 'canned Messages',
  CONTACT_GROUPS: 'contact Groups',
  SENDER_GROUPS: 'sender Groups',
  SERVICES: 'services',
  INTEGRATION: 'integration',
  ALERTS: 'alerts',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};
