import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Button, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { axiosGet, axiosPatch } from '../../services/http.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import DeleteModal from '../../components/Common/DeleteModal';
import { setLoader } from '../../store/actions';
import { setShowUploadBlockedNumbersModal } from '../../store/blocked-numbers/actions';
import UploadBlockedNumbers from './Modal/UploadBlockedNumbers';
import DataGrid from '../../components/DataTable';

class BlockedNumbers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blockedNumbers: [],
      selectedContacts: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      contactListColumns: [
        {
          header: 'Name',
          accessorKey: 'name',
        },
        {
          header: 'Number',
          accessorKey: 'phoneNumberString',
        },
        {
          header: 'Trigger Event',
          accessorKey: 'dndTrigger',
        },
        {
          accessorKey: 'menu',
          enableSorting: false,
          header: 'Action',
          Cell: ({ row }) => {
            const originalRow = row?.original;
            return (
              <div className="d-flex gap-3">
                <Link to="#">
                  <Badge color="success-subtle" className="text-success">
                    <i
                      className="bx bx-lock-open-alt font-size-18"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Unblock"
                      id="unblock"
                      onClick={() => {
                        this.onclickUnblock(originalRow);
                      }}
                    />
                  </Badge>
                </Link>
              </div>
            );
          },
        },
      ],
      tooltipOpen: false,
    };
    this.handleUnblockNumbers = this.handleUnblockNumbers.bind(this);
    this.handleRowSelection = this.handleRowSelection.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetAllcontacts(page);
  }

  handleUserClicks = () => {
    this.toggle();
  };

  handlePageClick = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllcontacts(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetAllcontacts(1, newPageSize);
    });
  };

  /* Insert,Update Delete data */
  handleGetAllcontacts = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      // onSetLoader(true);
      const response = await axiosGet(
        `contacts?page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&dnd=true&sortBy=createdAt:desc`,
      );
      if (response?.status === true) {
        this.setState({
          blockedNumbers: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error at handleGetAllcontacts :', error);
    }
  };

  async handleUnblockNumbers(selectedContacts) {
    const { page } = this.state;
    try {
      if (selectedContacts.length === 0) {
        console.error('No contacts found!');
        return;
      }

      // Extract phone numbers from selected contacts
      const phoneNumbers = selectedContacts.map((contact) => contact.phoneNumberString);
      const block = false;

      // Send a PATCH request to unblock contacts
      const response = await axiosPatch('contacts/update-dnd-status', {
        phoneNumbers,
        block,
        dndTrigger: ' ',
      });

      if (response?.status === true) {
        this.handleGetAllcontacts(page);
        toast.success(response?.message || 'Contact Unblocked successfully!');
        this.setState({ deleteModal: false, selectedContacts: [] });
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
        this.setState({ deleteModal: false });
      }
    } catch (error) {
      console.error('Error while unblocking contacts:', error.message);
    }
  }

  // Method to handle row selection
  handleRowSelection = (row, isSelected) => {
    if (isSelected) {
      // Add selected contact to the state
      this.setState((prevState) => ({
        selectedContacts: [...prevState.selectedContacts, row],
      }));
    } else {
      // Remove deselected contact from the state
      this.setState((prevState) => ({
        selectedContacts: prevState.selectedContacts.filter((contact) => contact.id !== row.id),
      }));
    }
  };

  onclickUnblock = (row) => {
    this.setState({ deleteModal: true, selectedContacts: [row] });
  };

  toggleTooltip = () => {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  downloadSampleCSV = () => {
    // Sample CSV content
    const csvContent = 'countyCode,mobile\n1,3934919311\n1,3934919322\n1,3934919333';
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');

    // Set the download attribute and create a URL for the Blob
    link.download = 'sample.csv';
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the document and trigger a click
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetAllcontacts(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedContacts: selectedRowsData });
  };

  toggle = () => {
    const { onSetShowUploadBlockedNumbersModal } = this.props;
    onSetShowUploadBlockedNumbersModal();
  };

  render() {
    // meta title
    document.title = 'dnd';

    const {
      blockedNumbers, deleteModal, page, selectedContacts, contactListColumns, totalSize,
    } = this.state;

    return (
      <>
        <UploadBlockedNumbers
          getAllBlockContacts={() => {
            this.handleGetAllcontacts(page);
          }}
        />
        <div className="">
          <Container fluid>
            <h4 className="mb-2 font-size-17">DND</h4>
            {/* Render Breadcrumbs */}
            <DeleteModal
              show={deleteModal}
              buttonText="Yes, Unblock it!"
              onDeleteClick={() => this.handleUnblockNumbers(selectedContacts)}
              onCloseClick={() => this.setState({ deleteModal: false, selectedContacts: [] })}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="text-sm-end me-3 d-flex justify-content-end">
                        <button
                          className="btn download-csv-btn me-3"
                          onClick={this.downloadSampleCSV}
                          type="button"
                        >
                          Download Sample CSV file
                        </button>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary me-3"
                          onClick={this.toggle}
                        >
                          <i className="bx bx-lock-open-alt me-1" />
                          Import Csv
                        </Button>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          disabled={selectedContacts.length === 0}
                          onClick={() => this.handleUnblockNumbers(selectedContacts)} // Modify the onClick event to trigger unblocking
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Unblock Numbers
                        </Button>
                      </div>
                    </Col>
                    <div className="mt-3">
                      <DataGrid
                        data={blockedNumbers}
                        columns={contactListColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

BlockedNumbers.propTypes = {
  onSetShowUploadBlockedNumbersModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowUploadBlockedNumbersModal: () => dispatch(setShowUploadBlockedNumbersModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

const mapStateToProps = ({ blockedNumbers }) => ({
  showUploadBlockedNumberModal: blockedNumbers.showUploadBlockedNumberModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BlockedNumbers));
