import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, UncontrolledTooltip, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { axiosDel, axiosGet, axiosPost } from '../../services/http.service';
import ContactForm from '../Contacts/Contact Groups/Modal/ContactForm';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import {
  setFilterData,
  setShowContactFormModal,
  setShowGroupFormModal,
} from '../../store/contacts/actions';
import emptyContacts from '../../assets/images/contacts/emptyContact.png';
import { setLoader, toggleCallWidgetVisibility } from '../../store/actions';
import ViewContact from './Modal/ViewContact';
import GroupForm from './Modal/GroupForm';
import { sliceStringWithEllipsis } from '../../helpers/commonHelpers';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import DisplayImage from '../../components/DisplayImage';
import AdvancedFilter from '../../components/AdvancedFilter';
import { SUBTLE_COLORS } from '../../constants/colors.constant';
import DataGrid from '../../components/DataTable';
import { CONDITION_OPTIONS, FIELD_NAMES } from '../../constants/field.constant';

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewContactOpen: false,
      contacts: [],
      contact: '',
      isEdit: false,
      contactIdToDelete: '',
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      searchQuery: '',
      deleteModal: false,
      selectedContacts: [],
      contactListColumns: [
        {
          header: 'Name',
          accessorKey: 'fullName',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue || '-'}</span>,
        },
        {
          header: 'Email',
          accessorKey: 'email',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue || '-'}</span>,
        },
        {
          header: 'Tags',
          accessorKey: 'tags',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const tags = renderedCellValue || [];
            const maxWidth = 250;
            let totalWidth = 0;

            const { visibleTags, hiddenTags } = tags.reduce(
              (acc, tag) => {
                const { tagName } = tag;
                const tagWidth = tagName?.length * 8 + 20; // Approximate width calculation based on the name length
                if (totalWidth + tagWidth <= maxWidth) {
                  acc.visibleTags.push(tag);
                } else {
                  acc.hiddenTags.push(tag.tagName);
                }
                totalWidth += tagWidth;
                return acc;
              },
              { visibleTags: [], hiddenTags: [] },
            );

            const tooltipId = `UncontrolledTooltipTag_${rowIndex}`;

            return (
              <div className="d-flex gap-2 flex-nowrap text-nowrap overflow-hidden">
                {visibleTags.map((tag, index) => {
                  const colorClass = SUBTLE_COLORS[index % SUBTLE_COLORS.length];
                  return (
                    <Badge
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className={`${colorClass.bg} ${colorClass.text} d-flex align-items-center flex-shrink-0 p-2`}
                    >
                      {tag.tagName}
                    </Badge>
                  );
                })}
                {hiddenTags.length > 0 && (
                  <>
                    <Badge
                      className="bg-light text-dark d-flex align-items-center flex-shrink-0 p-2 rounded-4"
                      id={tooltipId}
                    >
                      +
                      {hiddenTags.length}
                    </Badge>
                    <UncontrolledTooltip placement="bottom" target={tooltipId}>
                      <span>{hiddenTags.join(', ')}</span>
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            );
          },
          filterFn: (row, columnId, filterValue) => {
            const tags = row.getValue(columnId) || [];
            return tags.some((tag) => tag.tagName.toLowerCase().includes(filterValue.toLowerCase()));
          },
          enableColumnFilter: true,
        },
        {
          header: 'Mobile Number',
          accessorKey: 'phoneNumberString',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Address',
          accessorKey: 'address',
          Cell: ({ renderedCellValue, row }) => {
            const rowIndex = row?.id;
            const tooltipId = `UncontrolledTooltip_${rowIndex}`;
            const slicedContent = sliceStringWithEllipsis(30, renderedCellValue);
            return (
              <>
                <UncontrolledTooltip placement="bottom" target={tooltipId}>
                  {renderedCellValue}
                </UncontrolledTooltip>
                <span id={tooltipId}>{slicedContent}</span>
              </>
            );
          },
        },
        {
          header: 'Zip Code',
          accessorKey: 'zip',
          Cell: ({ renderedCellValue }) => (
            <span>{renderedCellValue !== ' ' ? renderedCellValue || '-' : '-'}</span>
          ),
        },
        {
          header: 'City',
          accessorKey: 'city',
          Cell: ({ renderedCellValue }) => (
            <span>{renderedCellValue !== ' ' ? renderedCellValue || '-' : '-'}</span>
          ),
        },
        {
          header: 'State',
          accessorKey: 'state',
          Cell: ({ renderedCellValue }) => <span>{renderedCellValue || '-'}</span>,
        },
        {
          accessorKey: 'menu',
          header: 'Actions',
          enableSorting: false,
          Cell: ({ row }) => {
            const contact = row?.original;
            return (
              <div className="d-flex gap-3">
                <Badge color="success-subtle" className="text-success">
                  <i
                    className="mdi mdi-eye font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Edit"
                    id="edit"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click event from firing
                      this.toggleViewContact(contact); // Open the contact view
                    }}
                  />
                </Badge>
                <Badge color="info-subtle" className="text-info">
                  <i
                    className="mdi mdi-phone-outline font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Call"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleCallIconClick(`+${contact.countryCode}${contact.phoneNumber}`);
                    }}
                  />
                </Badge>
                <Badge color="danger-subtle" className="text-danger">
                  <i
                    className="mdi mdi-trash-can-outline font-size-18"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.onClickDelete(contact);
                    }}
                  />
                </Badge>
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.getAllContacts(page);
  }

  handleCallIconClick = (phoneNumber) => {
    const { onToggleCallWidgetVisibility } = this.props;
    onToggleCallWidgetVisibility(phoneNumber);
  };

  toggle = () => {
    const { onSetShowContactFormModal } = this.props;
    onSetShowContactFormModal();
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  handlePageClick = (selectedPage) => {
    const { filterData } = this.props;
    this.setState({ page: selectedPage }, () => {
      if (Object.keys(filterData).length) {
        this.getContactsByFilter(filterData);
      } else {
        this.getAllContacts(selectedPage);
      }
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.getAllContacts(1, newPageSize);
    });
  };

  handleUserClicks = () => {
    this.setState({ contact: '', isEdit: false });
    this.toggle();
  };

  onClickDelete = (contact) => {
    this.setState({ contactIdToDelete: contact.id, deleteModal: true });
  };

  updateContactState = (contactData) => {
    this.setState({ contact: contactData });
  };

  /* Insert,Update Delete data */

  getAllContacts = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    let url = `contacts?page=${page}&limit=${size}&sortBy=createdAt:desc`;
    if (searchQuery) {
      url += `&q=${searchQuery}`;
    }
    try {
      // onSetLoader(true);
      const contacts = await axiosGet(url);
      if (contacts?.status) {
        onSetLoader(false);
        this.setState({
          contacts: contacts?.data?.results,
          totalSize: contacts?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting all contacts :', error);
    }
  };

  getContactsByFilter = async (data) => {
    const { onSetLoader } = this.props;
    const { page, size } = this.state;
    try {
      // onSetLoader(true);
      const contacts = await axiosPost(`contacts/filter?page=${page}&limit=${size}`, data);
      if (contacts?.status) {
        // onSetLoader(false);
        this.setState({
          contacts: contacts?.data?.result,
          totalSize: contacts?.data?.totalPages,
        });
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting all contacts :', error);
    }
  };

  handleDeleteContact = async (id) => {
    const { page } = this.state;
    try {
      const response = await axiosDel(`contacts/${id}`);
      if (response?.status) {
        toast.success(response?.message || 'Contact Deleted Successfully', {
          position: 'top-right',
        });
        this.getAllContacts(page);
        this.toggleDeleteModal();
      } else {
        toast.error(response?.message || 'Oops! something went wrong', {
          position: 'top-right',
        });
        this.toggleDeleteModal();
      }
    } catch (error) {
      console.error('error while deleting a contact :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.getAllContacts(page);
    });
  };

  handleAddGroup = () => {
    const { onSetShowGroupFormModal } = this.props;
    onSetShowGroupFormModal();
  };

  toggleViewContact = (contact) => {
    this.setState((prevState) => ({
      contact,
      isViewContactOpen: !prevState.isViewContactOpen,
    }));
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedContacts: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'Contacts List';

    const {
      deleteModal,
      contacts,
      contact,
      contactIdToDelete,
      isViewContactOpen,
      selectedContacts,
      isEdit,
      page,
      contactListColumns,
      totalSize,
    } = this.state;
    const { showContactFormModal, onSetFilterData } = this.props;
    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteContact(contactIdToDelete)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showContactFormModal && (
          <ContactForm
            isEdit={isEdit}
            contact={contact}
            getAllContacts={() => {
              this.getAllContacts(page);
            }}
          />
        )}
        <GroupForm
          contacts={selectedContacts}
          getAllContacts={() => {
            this.getAllContacts(page);
          }}
          selectedContacts={selectedContacts}
        />
        <ViewContact
          contact={contact}
          contactList={contacts}
          updateSelectedContact={(contactData) => this.updateContactState(contactData)}
          updateContactList={(updatedContactList) => this.setState({ contacts: updatedContactList })}
          isViewContactOpen={isViewContactOpen}
          toggleViewContact={this.toggleViewContact}
          getAllContacts={() => {
            this.getAllContacts(page);
          }}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Contacts" breadcrumbItem="Contacts List" />
            <Row>
              <Col lg="12">
                <AdvancedFilter
                  fields={FIELD_NAMES}
                  conditions={CONDITION_OPTIONS}
                  onFilterApplied={(filters) => {
                    if (filters?.conditions.length) {
                      onSetFilterData(filters);
                      this.getContactsByFilter(filters);
                    } else {
                      onSetFilterData({});
                      this.getAllContacts(page);
                    }
                  }}
                />
                <Card>
                  <CardBody>
                    <Col sm="12" className="d-flex justify-content-end align-items-center">
                      <div className="d-flex">
                        <div className="text-sm-end me-3">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            label="Add New Contact"
                            icon="mdi mdi-plus-circle-outline"
                            onClick={this.handleUserClicks}
                            iconLeft
                          />
                        </div>
                        <div className="text-sm-end me-3">
                          <Link to="/import-contacts">
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary"
                              label="Import Contacts"
                              icon="mdi mdi-upload"
                              onClick={this.handleUserClicks}
                              iconLeft
                            />
                          </Link>
                        </div>
                        <div className="text-sm-end">
                          <Button
                            color="primary"
                            className="font-16 btn-block btn btn-primary"
                            label="Create Group"
                            icon="mdi mdi-plus-circle"
                            onClick={this.handleAddGroup}
                            disabled={selectedContacts.length < 1}
                            iconLeft
                          />
                        </div>
                      </div>
                    </Col>
                    {contacts?.length ? (
                      <div className="mt-3">
                        <DataGrid
                          data={contacts}
                          columns={contactListColumns}
                          enableColumnResizing={false}
                          enableEditing={false}
                          enableGlobalFilter
                          enableColumnFilterModes
                          onRowSelectionChange={this.handleRowSelection}
                          totalSize={totalSize}
                          selectedRows={selectedContacts}
                          onPageChange={this.handlePageClick}
                          onPageSizeChange={this.handleLimitChange}
                          initialPage={0}
                          initialPageSize={10}
                          onSearch={this.debounceSearch}
                          onRowClick={(contactData) => this.toggleViewContact(contactData)}
                        />
                      </div>
                    ) : (
                      <DisplayImage src={emptyContacts} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

ContactsList.propTypes = {
  onSetShowContactFormModal: PropTypes.func,
  onSetShowGroupFormModal: PropTypes.func,
  onSetLoader: PropTypes.func,
  showContactFormModal: PropTypes.bool,
  filterData: PropTypes.object,
  onToggleCallWidgetVisibility: PropTypes.func,
  onSetFilterData: PropTypes.func,

  row: PropTypes.shape({
    original: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  onSetShowContactFormModal: () => dispatch(setShowContactFormModal()),
  onSetShowGroupFormModal: () => dispatch(setShowGroupFormModal()),
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onToggleCallWidgetVisibility: (phoneNumber) => dispatch(toggleCallWidgetVisibility(phoneNumber)),
  onSetFilterData: (data) => dispatch(setFilterData(data)),
});

const mapStateToProps = ({ contacts }) => ({
  showContactFormModal: contacts.showContactFormModal,
  showGroupFormModal: contacts.showGroupFormModal,
  filterData: contacts.filterData,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactsList));
