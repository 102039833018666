import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Card, CardBody, Col, Container, Row, Badge,
} from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { callType } from '../../constants/voice/call.constant';
import { axiosGet } from '../../services/http.service';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { setLoader } from '../../store/actions';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import PhoneNumberWithFlag from '../../components/PhoneNumberWithFlag';
import { DATE_CONSTANTS } from '../../constants/datetime.constant';
import DataGrid from '../../components/DataTable';
import { capitalizeFirstLetter } from '../../utils/general';

class Voicemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callLogsWithVoicemail: [],
      page: PAGE,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      deleteModal: false,
      searchQuery: '',
      selectedRows: [],
      voicemailLogsColumns: [
        {
          header: 'Time',
          accessorKey: 'createdAt',
          Cell: ({ renderedCellValue }) => {
            const formattedDate = moment(renderedCellValue).format(
              DATE_CONSTANTS.FORMATS.MM_DD_YYYY,
            );
            const formattedTime = moment(renderedCellValue).format(DATE_CONSTANTS.FORMATS.HH_MM_SS);
            return (
              <div>
                {formattedDate}
                <br />
                {formattedTime}
              </div>
            );
          },
        },
        {
          header: 'To',
          accessorKey: 'to',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'From',
          accessorKey: 'from',
          Cell: ({ renderedCellValue }) => <PhoneNumberWithFlag phoneNumber={renderedCellValue} />,
        },
        {
          header: 'Type',
          accessorKey: 'type',
          formatter({ row }) {
            const originalRow = row?.original;
            if (originalRow.type === 'inbound') {
              return (
                <Badge color="success-subtle" pill className="fs-6 text-success">
                  <i className="mdi mdi-phone-incoming" />
                  {' '}
                  {capitalizeFirstLetter(callType.INBOUND)}
                </Badge>
              );
            }
            return (
              <Badge color="primary-subtle" pill className="fs-6 text-primary">
                <i className="mdi mdi-phone-outgoing" />
                {' '}
                {capitalizeFirstLetter(callType.OUTBOUND)}
              </Badge>
            );
          },
        },
        {
          header: 'Duration',
          accessorKey: 'duration',
        },
        {
          header: 'Voicemail',
          accessorKey: 'voicemailTranscription',
          Cell: ({ renderedCellValue }) => (
            <audio controls>
              <source src={renderedCellValue} type="audio/mpeg" />
            </audio>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleGetVoicemailLogs(page);
  }

  handlePageClick = (selectedPage) => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetVoicemailLogs(selectedPage);
    });
  };

  handleLimitChange = (newPageSize) => {
    this.setState({ size: newPageSize, page: 1 }, () => {
      this.handleGetVoicemailLogs(1, newPageSize);
    });
  };

  handleGetVoicemailLogs = async (page) => {
    const { onSetLoader } = this.props;
    const { searchQuery, size } = this.state;
    try {
      // onSetLoader(true);
      const response = await axiosGet(
        `calldailer?isVoicemail=true&page=${page}&limit=${size}${
          searchQuery ? `&q=${searchQuery}` : ''
        }&sortBy=createdAt:desc`,
      );
      if (response?.status) {
        this.setState({
          callLogsWithVoicemail: response?.data?.results,
          totalSize: response?.data?.totalPages,
        });
        onSetLoader(false);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('error while getting call logs :', error);
    }
  };

  debounceSearch = (value) => {
    const { page } = this.state;
    this.setState({ searchQuery: value }, () => {
      this.handleGetVoicemailLogs(page);
    });
  };

  handleRowSelection = (selectedRowsData) => {
    this.setState({ selectedRows: selectedRowsData });
  };

  render() {
    // meta title
    document.title = 'voicemail';

    const {
      callLogsWithVoicemail, deleteModal, voicemailLogsColumns, totalSize, selectedRows,
    } = this.state;

    return (
      <>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Logs" breadcrumbItem="Voicemail List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="mt-3">
                      <DataGrid
                        data={callLogsWithVoicemail}
                        columns={voicemailLogsColumns}
                        enableColumnResizing={false}
                        enableColumnFilterModes
                        enableGlobalFilter
                        enableEditing={false}
                        selectedRows={selectedRows}
                        onRowSelectionChange={this.handleRowSelection}
                        totalSize={totalSize}
                        onPageChange={this.handlePageClick}
                        onPageSizeChange={this.handleLimitChange}
                        initialPage={0}
                        initialPageSize={10}
                        onSearch={this.debounceSearch}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Voicemail.propTypes = {
  onSetLoader: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),

  renderedCellValue: PropTypes.any,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Voicemail));
