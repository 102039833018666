import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styled } from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { CHANNEL_TYPES } from '../../../../../constants/channels/channels.constant';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import HField from '../../../../../components/HField';
import LabelValue from '../../../../../components/LabelValue';
import Select from '../../../../../components/Select';
import DateTime from '../../../../../components/DateTime';
import Row from '../../../../../components/Row';
import Col from '../../../../../components/Col';
import IconText from '../../../../../components/IconText';
import CustomErrorMessage from '../../../../../components/CustomErrorMessage';
import { resetBroadcastForm, setLoader, setStepFourData } from '../../../../../store/actions';
import * as bulkWhatsappServices from '../../../../../services/api/bulkWhatsapp.service';
import * as contactGroupServices from '../../../../../services/api/contactGroups.service';
import {
  SCHEDULE_TYPES, SCHEDULE_TYPES_ARRAY, SCHEDULED_IMMEDIATELY_OBJECT, SCHEDULED_LATER_OBJECT,
} from '../../../../../constants/inbox/message.constant';
import { DEFAULT_TIMEZONE, TIME_ZONE_OPTIONS } from '../../../../../constants/datetime.constant';

const StyledCard = styled(Card)`
  margin-top: 1.5rem;
  border: 1px solid var(--bs-gray-500);
`;

const StyledRow = styled(Row)`
  width: 103%;
`;

const StyledIconText = styled(IconText)`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;

  i {
    font-size: 1.2rem;
    margin-bottom: 0;
    margin-right: 0.2rem;
  }
`;

const FormCard = styled(Card)`
  border: 1px solid var(--bs-gray-500);

  .p-card-body {
    padding-bottom: 0.5rem;
  }
`;

const StyledDiv = styled.div`
`;

const StyledSelect = styled(Select)`
  padding: 0;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 1rem 0.4rem;
  border-radius: 0.3rem;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        broadcastName: '',
        scheduleType: SCHEDULE_TYPES.SCHEDULED_LATER,
        dateTime: '',
        timezone: DEFAULT_TIMEZONE.value,
        contactCount: 0,
      },
    };
  }

  componentDidMount() {
    const { broadcastData, selectedContactGroup, broadCastEditData } = this.props;
    this.setState({
      initialValues: {
        broadcastName: broadCastEditData?.broadcastName,
        scheduleType: SCHEDULE_TYPES.SCHEDULED_LATER,
        dateTime: broadCastEditData?.broadcastDateTime,
        timezone: broadCastEditData?.broadcastTimeZone,
        ...broadcastData,
      },
      contactCount: selectedContactGroup?.contactCount,
    });

    if (!isNumber(selectedContactGroup?.contactCount)) {
      this.getContactsCount(selectedContactGroup?.id);
    }
  }

  getContactsCount = async (contactGroupId) => {
    const { onSetLoader } = this.props;

    try {
      // onSetLoader(true);
      const response = await contactGroupServices.getContactCount(contactGroupId);
      if (response?.status) {
        this.setState({ contactCount: response?.data?.contactCount ?? 0 });
      } else {
        toast.error(response?.message || 'Oops! Something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      toast.error(error?.message || 'Oops! Something went wrong');
      console.error('error at handleGetAllContactGroups :', error);
      onSetLoader(false);
    }
  }

  handleOnSubmit = async (values) => {
    const {
      selectedContactGroup,
      selectedWhatsappNumberData,
      onSetLoader,
      storableTemplateData,
      onResetBroadcastForm,
      history,
    } = this.props;
    try {
      // onSetLoader(true);
      const response = await bulkWhatsappServices.storeWhatsappTemplate(storableTemplateData);
      if (response?.status) {
        const {
          scheduleType, broadcastName, dateTime, timezone,
        } = values;
        const templateId = response?.data?.id;

        // This key is introduced to ensure that the server's current time is used, rather than the user's local machine time, when sending immediately.
        const sendImmediate = scheduleType === SCHEDULE_TYPES.SEND_IMMEDIATELY;

        const campaignData = {
          name: broadcastName,
          senderNumberId: selectedWhatsappNumberData?.id,
          contactGroupId: selectedContactGroup?.id,
          templateId,
          whatsappData: {
            dynamicDate: new Date(),
          },
          dateTime: dateTime || '',
          timezone,
          sendImmediate,
        };

        const campaignResponse = await bulkWhatsappServices.createWhatsappCampaign(campaignData);
        if (campaignResponse?.status) {
          toast.success(campaignResponse?.message || 'Whatsapp Campaign created successfully!');
          // Reset all form values from redux
          onResetBroadcastForm();

          history.push({
            pathname: '/whatsapp-broadcast',
            state: { channel: CHANNEL_TYPES.WHATSAPP },
          });
        } else {
          toast.error(campaignResponse?.message || 'Oops! something went wrong');
        }
      } else {
        toast.error(response?.message || 'Oops! something went wrong');
      }
      onSetLoader(false);
    } catch (error) {
      toast.error(error?.message || 'Oops! something went wrong');
      onSetLoader(false);
    }
  };

  setFormData(formData) {
    const { onSetStepFourData } = this.props;

    // Set values in Redux
    onSetStepFourData(formData);
  }

  render() {
    const { initialValues, contactCount } = this.state;
    const {
      selectedContactGroup, selectedWhatsappNumberData, templateValues,
    } = this.props;

    const validationSchema = Yup.object().shape({
      broadcastName: Yup.string().required('Broadcast Name is required'),
      scheduleType: Yup.string().required('Send Broadcast is required'),
      dateTime: Yup.string().when(
        'scheduleType',
        {
          is: SCHEDULE_TYPES.SCHEDULED_LATER,
          then: Yup.string().required('Date & Time is required'),
          otherwise: Yup.string(),
        },
      ),
      timezone: Yup.string().when(
        'scheduleType',
        {
          is: SCHEDULE_TYPES.SCHEDULED_LATER,
          then: Yup.string().required('Please select a timezone'),
          otherwise: Yup.string(),
        },
      ),
    });

    return (
      <StyledCard>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleOnSubmit}
        >
          {({
            errors, touched, values, setFieldValue, setFieldError,
          }) => (
            <Form
              onChange={async (event) => {
                const { value } = event.target;

                const broadcastData = { ...values, [event.target.name]: value };

                this.setFormData(broadcastData);
              }}
            >
              <StyledDiv>
                <StyledIconText icon="mdi mdi-bullhorn" text="Broadcast details" />
                <FormCard>
                  <HField
                    label="Broadcast name"
                    placeholder="Enter broadcast name"
                    type="text"
                    fieldClassName={
                      `form-control ${
                        errors.broadcastName && touched.broadcastName ? 'is-invalid' : ''}`
                    }
                    name="broadcastName"
                    required
                    formikField
                  />
                  <HField
                    label="Send broadcast"
                    name="sendBroadcast"
                    fieldClassName={
                      `form-control ${
                        errors.sendBroadcast && touched.sendBroadcast ? 'is-invalid' : ''}`
                    }
                  >
                    <StyledRow>
                      <Col lg={4}>
                        <StyledSelect
                          value={SCHEDULE_TYPES_ARRAY.reduce(
                            (acc, scheduleType) => (scheduleType.value === values.scheduleType
                              ? scheduleType
                              : acc),
                            {},
                          )}
                          onChange={(scheduleType) => {
                            setFieldValue('scheduleType', scheduleType?.value);
                            this.setFormData({ ...values, scheduleType: scheduleType?.value });
                          }}
                          options={SCHEDULE_TYPES_ARRAY}
                          optionIcon="mdi mdi-phone fs-6"
                        />
                      </Col>
                      {
                        values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER
                          ? (
                            <>
                              <Col lg={4}>
                                <DateTime
                                  id="myDateTimePicker"
                                  onDateTimeChange={(dateTime) => {
                                    setFieldValue('dateTime', new Date(dateTime).toISOString());
                                    this.setFormData({ ...values, dateTime: new Date(dateTime).toISOString() });
                                  }}
                                  minDate={new Date(new Date().setHours(0, 0, 0))}
                                  initialValue={values.dateTime ? new Date(values.dateTime) : null}
                                  onError={(errorMessage) => setFieldError('dateTime', errorMessage)}
                                  hideOnDateTimeSelect
                                  showButtonBar
                                />
                                <CustomErrorMessage
                                  message={errors.dateTime}
                                  showError={!!errors.dateTime}
                                />
                              </Col>
                              <Col lg={4}>
                                <StyledSelect
                                  value={TIME_ZONE_OPTIONS.reduce(
                                    (acc, timezoneOption) => (timezoneOption.value === values.timezone
                                      ? timezoneOption
                                      : acc),
                                    {},
                                  )}
                                  onChange={(selectedValue) => {
                                    setFieldValue('timezone', selectedValue?.value);
                                    this.setFormData({ ...values, timezone: selectedValue?.value });
                                  }}
                                  options={TIME_ZONE_OPTIONS}
                                  placeholder={<div>Select timezone</div>}
                                  className={
                                      `${errors.timezone && touched.timezone
                                        ? 'is-invalid'
                                        : ''}`
                                    }
                                />
                                <CustomErrorMessage
                                  message={errors.timezone}
                                  showError={!!errors.timezone}
                                />
                              </Col>
                            </>
                          )
                          : null
                      }
                    </StyledRow>
                  </HField>
                </FormCard>
              </StyledDiv>
              <StyledDiv>
                <StyledIconText icon="bx bx-group" text="Target Audience" />
                <FormCard>
                  <LabelValue
                    label="Number of Audiences"
                    value={contactCount ?? 0}
                    icon="bx bx-group"
                  />
                  <LabelValue
                    label="Segment"
                    value={selectedContactGroup?.name}
                  />
                </FormCard>
              </StyledDiv>
              <StyledDiv>
                <StyledIconText icon="mdi mdi-cellphone-information" text="Sender Info" />
                <FormCard>
                  <LabelValue
                    label="Sender Id"
                    value={selectedWhatsappNumberData?.name}
                  />
                  <LabelValue
                    label="Template Name"
                    value={templateValues?.templateName}
                  />
                </FormCard>
              </StyledDiv>
              <FlexCenter>
                <StyledButton
                  type="submit"
                  label={
                  values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER ? SCHEDULED_LATER_OBJECT.text : SCHEDULED_IMMEDIATELY_OBJECT.text
                }
                  icon={
                  values.scheduleType === SCHEDULE_TYPES.SCHEDULED_LATER ? SCHEDULED_LATER_OBJECT.icon : SCHEDULED_IMMEDIATELY_OBJECT.icon
                }
                />
              </FlexCenter>
            </Form>
          )}
        </Formik>
      </StyledCard>
    );
  }
}

Step4.propTypes = {
  selectedWhatsappNumberData: PropTypes.object,
  selectedContactGroup: PropTypes.object,
  storableTemplateData: PropTypes.object,
  templateValues: PropTypes.object,
  broadcastData: PropTypes.object,
  broadCastEditData: PropTypes.object,
  onSetLoader: PropTypes.func,
  onSetStepFourData: PropTypes.func,
  onResetBroadcastForm: PropTypes.func,
  history: PropTypes.any,
};

const mapStateToProps = ({ bulkWhatsapp }) => ({
  selectedWhatsappNumberData: bulkWhatsapp.step1.selectedWhatsappNumberData,
  templateValues: bulkWhatsapp.step2.templateValues,
  storableTemplateData: bulkWhatsapp.step2.storableTemplateData,
  selectedContactGroup: bulkWhatsapp.step3.selectedContactGroup,
  broadcastData: bulkWhatsapp.step4.broadcastData,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onSetStepFourData: (formData) => dispatch(setStepFourData(formData)),
  onResetBroadcastForm: () => dispatch(resetBroadcastForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Step4));
