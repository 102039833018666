import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import Draggable from 'react-draggable';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from '@twilio/voice-sdk';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getChannels } from '../../../utils/channels';
import { CHANNEL_CONNECT_STATUS } from '../../../constants/channels/channels.constant';
import { UserContext } from '../../UserProvider/UserProvider';
import KeypadContainer from './KeypadContainer';
import CallControls from './CallControls';
import { DRAGGABLE_DEFAULT_POSITION, LIMIT } from '../../../constants/constants';

import { capitalizeFirstLetter } from '../../../utils/general';
import {
  STATUS_CLASS_MAP,
  STATUS_TEXT_MAP,
  StatusOptions,
} from '../../../constants/status.constant';
import { setIsCallInitiated, setTwilioVoiceToken } from '../../../store/actions';
import { axiosGet, axiosPost } from '../../../services/http.service';
import socketService from '../../../utils/socket';
import IncomingCall from './IncomingCall';
import Keypad from './Keypad';
import {
  getContactName,
  getInitials,
  sliceStringWithEllipsis,
} from '../../../helpers/commonHelpers';
import { CALL_TYPES, DEFAULT_RINGING_TEXT } from '../../../constants/voice/call.constant';
import Header from './Header';
import CallerInfo from './CallerInfo';

const customOption = (props) => (
  <components.Option {...props}>
    <i className="mdi mdi-phone me-1" />
    {props.data.label}
  </components.Option>
);

const customSingleValue = (props) => (
  <components.SingleValue {...props}>
    <i className="mdi mdi-phone me-1" />
    {props.data.label}
  </components.SingleValue>
);

function CallWidget({
  className, toggleVisibility, to, from,
}) {
  const dispatch = useDispatch();
  const twilioVoiceToken = useSelector((state) => state.dialPad.twilioVoiceToken);
  const globalPhoneNumber = useSelector((state) => state.CallWidget.globalPhoneNumber);
  const fromNumber = useSelector((state) => state.CallWidget.fromNumber);

  const { userData } = useContext(UserContext);

  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [isOnCall, setIsOnCall] = useState(false);
  const [isKeypadOpen, setIsKeypadOpen] = useState(false);
  const [status, setStatus] = useState(StatusOptions.ACTIVE.value);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dialPhoneNumber, setDialPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(globalPhoneNumber || '');
  const [selectedOption, setSelectedOption] = useState(fromNumber || '');
  const [optionGroup, setOptionGroup] = useState([]);
  const [calls, setCalls] = useState('');
  const [currentCall, setCurrentCall] = useState('');
  const [isIncomingCallAccepted, setIsIncomingCallAccepted] = useState(false);
  const [incomingCall, setIncomingCall] = useState(false);
  const [showIncomingCall, setShowIncomingCall] = useState(true);
  const [initiatedCallAccepted, setInitiatedCallAccepted] = useState(false);
  const [showAcceptedCall, setShowAcceptedCall] = useState(false);
  const [isCallMuted, setIsCallMuted] = useState(false);
  const [userDisplayName, setUserDisplayName] = useState(null);
  const [callType, setCallType] = useState('');
  const [recordingSid, setRecordingSid] = useState('');
  const [callSid, setCallSid] = useState('');

  const deviceRef = useRef(null);
  const intervalIdRef = useRef(null);

  const getNumbers = async () => {
    try {
      const numberList = await axiosGet(`numbers?page=1&limit=${LIMIT}`);

      const options = numberList?.data?.results?.map((result) => ({
        value: result.phoneNumber,
        label: result.phoneNumber,
      }));
      const defaultOption = !_.isEmpty(options) ? options[0] : null;
      setOptionGroup(options);
      setSelectedOption(defaultOption);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetContactByPhoneNumberString = async (phoneNumberString) => {
    try {
      const response = await axiosGet(`contacts/${phoneNumberString}`);
      if (response.status) {
        setUserDisplayName(response.data);
      } else {
        setUserDisplayName('');
      }
    } catch (error) {
      console.error('error at handleGetContactByPhoneNumberString :', error);
    }
  };

  const startTimer = () => {
    if (!isRunning) {
      setIsRunning(true);
      intervalIdRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  const stopTimer = () => {
    setIsRunning(false);
    setTime(0);
    clearInterval(intervalIdRef.current);
  };

  const registerDevice = async () => {
    try {
      await deviceRef.current.register();
    } catch (error) {
      console.error('dialpad error: ', error);
    }
  };

  const handleGetTwilioVoiceToken = async () => {
    try {
      const organizationId = userData?.organizationId;
      const response = await axiosGet(`organization/${organizationId}/voice-access-token`);
      if (response.status) {
        dispatch(setTwilioVoiceToken(response?.data));
        return response.data;
      }
      return null;
    } catch (error) {
      console.error('error at handleGetTwilioVoiceToken in app.js', error);
      return null;
    }
  };

  const handleCallEnd = () => {
    stopTimer();
    dispatch(setIsCallInitiated(false));
    setInitiatedCallAccepted(false);
    setShowAcceptedCall(false);
    setSelectedOption('');
    setPhoneNumber('');
    setIsRecording(false);
    setIsIncomingCallAccepted(false);
    setIsOnCall(false);
    setIncomingCall(false);
    setCurrentCall('');
    setCalls('');
  };

  const setupDeviceListeners = () => {
    const channels = userData?.organization?.channels?.length > 0 ? userData?.organization?.channels : [];
    const channelsStatus = getChannels(channels);
    const ChannelConnected = Object.values(channelsStatus).some((channel) => channel?.status === CHANNEL_CONNECT_STATUS.CONNECTED);
    if (deviceRef.current) {
      deviceRef.current.on('error', (error) => {
        console.error('Error at call widget : ', error);
      });

      deviceRef.current.on('tokenWillExpire', async () => {
        if (userData?.organization?.channels?.length > 0 && ChannelConnected) {
          const token = await handleGetTwilioVoiceToken();
          deviceRef.current.updateToken(token);
        }
      });

      deviceRef.current.on('unregistered', async () => {
        if (userData?.organization?.channels?.length > 0 && ChannelConnected) {
          const token = await handleGetTwilioVoiceToken();
          if (token) {
            deviceRef.current.updateToken(token);
          }
        }
      });

      deviceRef.current.on('incoming', async (call) => {
        await handleGetContactByPhoneNumberString(call?.parameters?.From);
        setCurrentCall(call);
        setCallSid(call?.parameters?.CallSid);
        setCallType('incoming');
        setIncomingCall(true);

        call.on('accept', () => {
          startTimer();
        });
        call.on('disconnect', () => {
          handleCallEnd();
        });
        call.on('reject', () => {
          handleCallEnd();
        });
      });
    }
  };

  useEffect(() => {
    getNumbers();

    if (!deviceRef.current) {
      deviceRef.current = new Device(twilioVoiceToken, {
        enableImprovedSignalingErrorPrecision: true,
        logLevel: 1,
        codecPreferences: ['opus', 'pcmu'],
        enableRingingState: true,
      });

      registerDevice();
    }

    setupDeviceListeners();

    socketService.on('startRecording', (data) => {
      setCallSid(data?.callSid);
      setRecordingSid(data?.recordingSid);
      toast.success(data?.message);
    });

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    if (to && from) {
      setPhoneNumber(to);
      setSelectedOption({ label: from, value: from });
    }
  }, [to, from]);

  // Make sure to clean up the interval when the component unmounts
  useEffect(
    () => () => {
      clearInterval(intervalIdRef.current);
    },
    [],
  );

  const handleCallClick = async () => {
    try {
      await handleGetContactByPhoneNumberString(phoneNumber);
      if (deviceRef.current && phoneNumber && Object.keys(selectedOption).length) {
        const newCall = await deviceRef.current.connect({
          params: {
            To: phoneNumber,
            From: selectedOption?.value,
            CurrentUserId: userData?.id,
            OutBoundCall: true,
          },
        });
        setCalls(newCall);
        setCallSid(JSON.stringify(newCall)?.parameters?.CallSid);
        setCallType('outgoing');
        dispatch(setIsCallInitiated(true));

        newCall.on('ringing', () => {
          setInitiatedCallAccepted(true);
          setShowAcceptedCall(true);
        });
        newCall.on('accept', () => {
          startTimer();
        });
        newCall.on('disconnect', () => {
          handleCallEnd();
        });
        newCall.on('cancel', () => {
          handleCallEnd();
        });
        newCall.on('reject', () => {
          handleCallEnd();
        });
        newCall.on('error', (error) => {
          console.error('an error occurred', error);
          handleCallEnd();
        });
      }
    } catch (error) {
      console.error('Error making call:', error);
    }
  };

  const handleStopRecording = async () => {
    try {
      const data = {
        callSid,
        recordingSid,
      };
      const response = await axiosPost('calldailer/stop-recording', data);
      if (response?.status) {
        setIsRecording(false);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('error while stopping the call recording :', error);
    }
  };

  const handleStartRecording = async () => {
    try {
      const data = {
        callSid: initiatedCallAccepted
          ? calls?.parameters?.CallSid
          : currentCall?.parameters?.CallSid,
      };
      const response = await axiosPost('calldailer/start-recording', data);
      if (response?.status) {
        setIsRecording(true);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error('error while starting the call recording :', error);
    }
  };
  const handleRecordToggle = () => {
    if (isRecording) {
      handleStopRecording();
    } else {
      handleStartRecording();
    }
  };

  const handleHoldToggle = () => {
    setIsOnHold((prevState) => !prevState);
  };

  const onKeypadToggle = () => {
    setIsKeypadOpen((prevState) => !prevState);
  };

  const handleDialNumberClick = (number) => {
    if (isOnCall) {
      setDialPhoneNumber((prevState) => prevState + number);
    }
  };

  const handlePhoneNumberChange = (value) => {
    if (!isOnCall) {
      setPhoneNumber(value);
    }
  };

  const handleDialNumberChange = (e) => {
    setDialPhoneNumber(e.target.value);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNumberClick = (number) => {
    setPhoneNumber((prevState) => prevState + number);
  };

  const handleClearClick = () => {
    setPhoneNumber('');
  };

  const handleAcceptCall = () => {
    currentCall.accept();
    setIncomingCall(false);
    setIsIncomingCallAccepted(true);
    setIsOnCall(true);
  };

  const handleRejectCall = () => {
    currentCall.reject();
    setIsIncomingCallAccepted(false);
    setIsOnCall(false);
    stopTimer();
  };

  const handleMuteAndUnMuteCall = () => {
    if (calls?.isMuted()) {
      calls?.mute(false);
    } else {
      calls?.mute(true);
    }

    setIsCallMuted(calls?.isMuted());
  };

  const handleMuteAndUnMuteIncomingCall = () => {
    if (currentCall.isMuted()) {
      currentCall.mute(false);
    } else {
      currentCall.mute(true);
    }

    setIsCallMuted(currentCall.isMuted());
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const hangupCall = () => {
    setIsOnCall(false);
    setShowAcceptedCall(false);
    setInitiatedCallAccepted(false);
    setIsIncomingCallAccepted(false);
    setCalls('');
    setCurrentCall('');
    setIsCallMuted(false);
    if (deviceRef.current) {
      deviceRef.current.disconnectAll();
    }
    if (isRecording) {
      handleStopRecording();
    }
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleWidgetMinimization = () => {
    setPhoneNumber('');
    toggleVisibility();
  };

  const handleMinimizeCallScreen = () => {
    setIsOnCall(false);
    setShowAcceptedCall(false);
  };

  const handleMaximizeCallScreen = () => {
    setIsOnCall(true);
    setShowAcceptedCall(true);
  };

  const handleMinimizeIncomingCallScreen = () => {
    setShowIncomingCall(false);
  };

  const handleMaximizeIncomingCallScreen = () => {
    setShowIncomingCall(true);
  };

  const handleOnStatusClick = (selectedStatus) => {
    setStatus(selectedStatus);
    toggleDropdown();
  };

  const statusText = STATUS_TEXT_MAP[status?.toLowerCase()];
  const statusClass = STATUS_CLASS_MAP[status?.toLowerCase()];
  const callerName = userData?.name;
  const organizationName = userData?.organizationName;
  const contactName = getContactName(userDisplayName);

  return (
    <>
      {(isIncomingCallAccepted && !isOnCall)
      || (!showAcceptedCall && calls)
      || (incomingCall && !isIncomingCallAccepted) ? (
        <div
          className="cursor-pointer on-call-btn d-flex justify-content-center align-items-center"
          onClick={
            incomingCall && !isIncomingCallAccepted
              ? handleMaximizeIncomingCallScreen
              : handleMaximizeCallScreen
          }
        >
          <span>
            <i className="mdi mdi-phone-in-talk text-light fw-bold fs-2" />
          </span>
        </div>
        ) : null}
      <div>
        {incomingCall && showIncomingCall ? (
          <IncomingCall
            displayName={userDisplayName || currentCall?.parameters || {}}
            acceptCall={handleAcceptCall}
            declineCall={handleRejectCall}
            handleMinimize={handleMinimizeIncomingCallScreen}
          />
        ) : null}
      </div>
      <div className={className}>
        {!incomingCall && !isIncomingCallAccepted && !initiatedCallAccepted ? (
          <Draggable bounds="html" defaultPosition={DRAGGABLE_DEFAULT_POSITION}>
            <div className="box chatWidget call-widget-container">
              <div className="call-widget-card">
                <Header handleOnClick={handleWidgetMinimization} />
                <CardBody className="call-widget-body overflow-hidden">
                  <div className="call-widget-caller-info">
                    <div className="d-flex align-items-center">
                      <div className="caller-avatar">{getInitials(callerName)}</div>
                      <div className="caller-details">
                        <span>{capitalizeFirstLetter(callerName)}</span>
                        <UncontrolledTooltip placement="bottom" target="orgName">
                          <span>{capitalizeFirstLetter(organizationName)}</span>
                        </UncontrolledTooltip>
                        <small className="fw-lighter text-secondary" id="orgName">
                          {capitalizeFirstLetter(sliceStringWithEllipsis(15, organizationName))}
                        </small>
                      </div>
                    </div>
                    <div className="status-dropdown">
                      <div className={`status-display ${statusClass}`} onClick={toggleDropdown}>
                        <span className={`status-indicator ${status?.toLowerCase()}`} />
                        <span className={`${statusText}`}>{status}</span>
                      </div>
                      {isDropdownOpen && (
                        <ul className="status-dropdown-menu">
                          {Object.values(StatusOptions).map((option) => (
                            <li
                              key={option.value}
                              className="status-dropdown-item"
                              onClick={() => handleOnStatusClick(option?.value)}
                            >
                              <span className={`status-indicator ${option?.value}`} />
                              <span className="fs-6 fw-lighter text-light">{option?.label}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <h6 className="fw-normal text-light pt-4 ps-3">Caller ID</h6>
                  <div className="mb-3 px-3 ">
                    <Select
                      options={optionGroup}
                      value={selectedOption}
                      onChange={handleSelect}
                      placeholder="Select company number "
                      components={{
                        Option: customOption,
                        SingleValue: customSingleValue,
                      }}
                      className=""
                      noOptionsMessage={() => 'No phone numbers available'}
                    />
                  </div>
                  <KeypadContainer
                    phoneNumber={phoneNumber || globalPhoneNumber}
                    handleNumberClick={handleNumberClick}
                    handleClearClick={handleClearClick}
                    handleCallClick={handleCallClick}
                    isOnCall={isOnCall}
                    handlePhoneNumberChange={handlePhoneNumberChange}
                  />
                </CardBody>
              </div>
            </div>
          </Draggable>
        ) : null}
      </div>
      {isOnCall || showAcceptedCall ? (
        <Draggable bounds="html" defaultPosition={DRAGGABLE_DEFAULT_POSITION}>
          <div className="call-widget-on-call">
            <Card className="call-widget-card">
              <Header handleOnClick={handleMinimizeCallScreen} />
              <CardBody>
                <div className="call-widget-on-call-info">
                  <div className="call-widget-caller-info">
                    <CallerInfo
                      contactName={contactName}
                      contactNumber={
                        userDisplayName?.phoneNumberString || currentCall?.parameters?.From
                      }
                      statusText={isRunning ? formatTime(time) : DEFAULT_RINGING_TEXT}
                    />
                  </div>
                  {isKeypadOpen && (
                    <>
                      <input
                        type="number"
                        className="invisible-input"
                        placeholder="Enter phone number"
                        onChange={handleDialNumberChange}
                        value={dialPhoneNumber}
                      />
                      <div className="key-pad">
                        <Keypad
                          handleNumberClick={handleDialNumberClick}
                          handleCallClick={handleCallClick}
                        />
                      </div>
                    </>
                  )}
                  <CallControls
                    isRecording={isRecording}
                    onRecordToggle={handleRecordToggle}
                    isOnHold={isOnHold}
                    onHoldToggle={handleHoldToggle}
                    isMuted={isCallMuted}
                    onMuteToggle={
                      initiatedCallAccepted
                        ? handleMuteAndUnMuteCall
                        : handleMuteAndUnMuteIncomingCall
                    }
                    onEndCall={hangupCall}
                    isKeypadOpen={isKeypadOpen}
                    onKeypadToggle={onKeypadToggle}
                    callAccepted={
                      callType === CALL_TYPES.OUTGOING
                        ? initiatedCallAccepted
                        : isIncomingCallAccepted
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </div>
        </Draggable>
      ) : null}
    </>
  );
}

CallWidget.propTypes = {
  className: PropTypes.string,
  toggleVisibility: PropTypes.func,
  to: PropTypes.string,
  from: PropTypes.string,
};

export default CallWidget;
