/* eslint-disable no-use-before-define */
import { toast } from 'react-toastify';
import parseNotificationData from '../utils/notificationUtils';
import {
  CAMPAIGN_EVENT_NAME,
  CONTACT_BLOCKED_EVENT_NAME,
  LOGIN_EVENT_NAME,
  NOTIFICATION_EVENT_NAME,
} from '../constants/socketEvents.constant';
import socketService from '../utils/socket';
import CustomToastContent from '../components/Common/NotificationToast';
import { addNotification, setLatestConversation, updateNotification } from '../store/actions';

export const initializeSocketConnection = async (
  getUserInfo,
  dispatch,
  notifications,
  handleGetUnreadMessages,
) => {
  try {
    const userData = await getUserInfo();

    if (!userData || !userData.id) {
      return;
    }

    const { id: userId } = userData;

    closeExistingConnection();
    initializeNewConnection(userId, userData);
    setupEventListeners(notifications, dispatch, handleGetUnreadMessages);
  } catch (error) {
    console.error('Failed to initialize socket connection:', error);
    toast.error('Failed to establish real-time connection. Please try again.');
  }
};

const closeExistingConnection = () => {
  if (socketService && typeof socketService.close === 'function') {
    socketService.close();
  }
};

const initializeNewConnection = (userId, userData) => {
  socketService.init(userId);
  socketService.emit(LOGIN_EVENT_NAME, userData);
};

const setupEventListeners = (notifications, dispatch, handleGetUnreadMessages) => {
  setupNotificationListener(notifications, dispatch);
  setupCampaignListener();
  setupContactBlockedListener(dispatch, handleGetUnreadMessages);
};

const setupNotificationListener = (notifications, dispatch) => {
  socketService.on(NOTIFICATION_EVENT_NAME, (data) => {
    if (!data) return;

    displayToast(data);
    const socketData = parseNotificationData(data);
    dispatch(addNotification(socketData));
    dispatch(setLatestConversation(data));
  });
};

const displayToast = (data) => {
  toast(CustomToastContent({ data }), { hideProgressBar: true });
};

const setupCampaignListener = () => {
  socketService.on(CAMPAIGN_EVENT_NAME, (data) => {
    if (data?.errorMessage) {
      toast.error(data.errorMessage);
    } else if (data?.message) {
      toast.success(data.message);
    }
  });
};

const setupContactBlockedListener = (dispatch, handleGetUnreadMessages) => {
  socketService.on(CONTACT_BLOCKED_EVENT_NAME, () => {
    dispatch(updateNotification([]));
    handleGetUnreadMessages();
  });
};

export const sendSocketMessage = (eventName, data) => {
  try {
    if (!socketService.isConnected()) {
      throw new Error('Socket is not connected');
    }
    socketService.emit(eventName, data);
  } catch (error) {
    console.error(`Failed to send socket message for event ${eventName}:`, error);
    toast.error('Failed to send message. Please try again.');
  }
};

export const receiveSocketMessage = (eventName, callback) => {
  try {
    if (!socketService.isConnected()) {
      throw new Error('Socket is not connected');
    }
    socketService.on(eventName, (data) => {
      try {
        callback(data);
      } catch (error) {
        console.error(`Error in socket message callback for event ${eventName}:`, error);
      }
    });
  } catch (error) {
    console.error(`Failed to set up socket message receiver for event ${eventName}:`, error);
    toast.error('Failed to set up message receiver. Please refresh the page.');
  }
};
