import { axiosGet, axiosPatch } from '../http.service';

// Function to get onboarding data
export const getOnboarding = async (organizationId) => {
  try {
    if (organizationId) {
      const response = await axiosGet(`onboarding/${organizationId}`);
      return response;
    }
    return null;
  } catch (error) {
    console.error('Error getting onboarding data:', error);
    throw error;
  }
};

// Function to update onboarding data
export const updateOnboarding = async (organizationId, updateData) => {
  try {
    if (organizationId) {
      const response = await axiosPatch(`onboarding/${organizationId}`, updateData);
      return response;
    }
    return null;
  } catch (error) {
    console.error('Error updating onboarding data:', error);
    throw error;
  }
};
