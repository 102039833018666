import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Badge, Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row,
} from 'reactstrap';
import classnames from 'classnames';
import DeleteModal from '../../components/Common/DeleteModal';
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from '../../constants/constants';
import { axiosDel, axiosGet, axiosPut } from '../../services/http.service';
import { setShowServicesModal } from '../../store/services/actions';
import { setLoader } from '../../store/actions';
import ServiceForm from './Modal/ServicesForm';
import DataGrid from '../../components/DataTable';
import { showToast } from '../../services/toastService';
import { toastType } from '../../constants/toast';

// price cell
function PriceCell({ renderedCellValue }) {
  return (
    <Badge color="primary-subtle" pill className="text-primary pt-1 fs-6">
      $
      {renderedCellValue}
    </Badge>
  );
}
// actions cell
function ActionsCell({
  row, handleServiceClick, onClickDelete, toggleActiveStatus,
}) {
  const service = row?.original;
  return (
    <div className="d-flex gap-3">
      <div>
        <Badge color="success-subtle" className="text-success">
          <i
            className="mdi mdi-pencil font-size-18 text-success"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="edit"
            id="edit-service"
            onClick={() => handleServiceClick(service)}
          />
        </Badge>
      </div>
      <div>
        <Badge
          color={service.isActive ? 'secondary-subtle' : 'primary-subtle'}
          className={service.isActive ? 'text-secondary' : 'text-primary'}
        >
          <i
            className={`mdi ${service.isActive ? 'mdi-eye-off' : 'mdi-eye'} font-size-18`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={service.isActive ? 'Deactivate' : 'Activate'}
            onClick={() => toggleActiveStatus(service)}
          />
        </Badge>
      </div>
      <div>
        <Badge color="danger-subtle" className="text-danger">
          <i
            className="mdi mdi-trash-can-outline font-size-18 text-danger"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="delete"
            onClick={() => onClickDelete(service)}
          />
        </Badge>
      </div>
    </div>
  );
}

// Main component
function Services({
  onSetLoader,
  showServicesFormModal,
  onSetShowServicesModal,
}) {
  const [services, setServices] = useState([]);
  const [service, setService] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(PAGE);
  const [size, setSize] = useState(SIZE_PER_PAGE);
  const [totalSize, setTotalSize] = useState(TOTAL_SIZE);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [refreshData, setRefreshData] = useState(false);

  const getAllServices = async (currentPage, limit = size) => {
    try {
      // onSetLoader(true);
      const options = {
        page: currentPage,
        limit,
      };
      const filter = {
        isActive: isActiveTab,
      };
      const response = await axiosGet(`services?options=${JSON.stringify(options)}&filter=${JSON.stringify(filter)}`);
      if (response?.status) {
        onSetLoader(false);
        setServices(response?.data?.results);
        setTotalSize(response?.data?.totalPages);
      } else {
        onSetLoader(false);
      }
    } catch (error) {
      onSetLoader(false);
      console.error('Error while getting services:', error);
    }
  };

  const onClickDelete = (currentService) => {
    setService(currentService);
    setDeleteModal(true);
  };

  const toggle = () => {
    onSetShowServicesModal();
  };

  const handleServiceClick = (currentService = null) => {
    const serviceInfo = currentService || '';
    setService(serviceInfo);
    setIsEdit(!!serviceInfo);
    toggle();
  };

  const handleRowSelection = (selectedRowsData) => {
    setSelectedRows(selectedRowsData);
  };

  const handlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleLimitChange = (newPageSize) => {
    setSize(newPageSize);
    setPage(1);
    getAllServices(1, newPageSize);
  };

  const toggleDeleteModal = () => {
    setDeleteModal((prevState) => !prevState);
  };

  const handleDeleteService = async () => {
    try {
      const response = await axiosDel(`services/${service?.id}`);
      if (response?.status) {
        showToast({
          content: response?.message || 'Service deleted successfully!',
          type: toastType.success,
        });
        getAllServices(page);
        toggleDeleteModal();
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! something went wrong',
        type: toastType.error,
      });
      console.error('Error while deleting service:', error);
    }
  };

  const toggleActiveStatus = async (serviceInfo) => {
    try {
      const updatedStatus = !serviceInfo?.isActive;
      const response = await axiosPut(`services/${serviceInfo?.id}`, {
        isActive: updatedStatus,
      });
      if (response?.status) {
        showToast({
          content: response?.message || 'Service status updated successfully!',
          type: toastType.success,
        });
        setRefreshData((prev) => !prev); // Trigger re-fetch
      } else {
        showToast({
          content: response?.message || 'Oops! something went wrong',
          type: toastType.error,
        });
      }
    } catch (error) {
      showToast({
        content: error?.message || 'Oops! something went wrong',
        type: toastType.error,
      });
      console.error('Error while updating service status:', error);
    }
  };

  useEffect(() => {
    getAllServices(page);
  }, [page, isActiveTab, refreshData]);

  const debounceSearch = (() => {
    let timeoutId;
    return (value) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setService(value);
      }, 300);
    };
  })();

  const [serviceListColumns] = useState([
    {
      header: 'Service Name',
      accessorKey: 'name',
    },
    {
      header: 'Price',
      accessorKey: 'price',
      Cell: PriceCell,
    },
    {
      header: 'Duration (in mins)',
      accessorKey: 'duration',
    },
    {
      header: 'Time Buffer (in mins)',
      accessorKey: 'timeBuffer',
    },
    {
      accessorKey: 'menu',
      header: 'Actions',
      enableSorting: false,
      Cell: (props) => (
        <ActionsCell
          {...props}
          handleServiceClick={handleServiceClick}
          onClickDelete={onClickDelete}
          toggleActiveStatus={toggleActiveStatus}
        />
      ),
    },
  ]);

  document.title = 'Service List';

  return (
    <>
      {/* Delete Modal */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteService}
        onCloseClick={() => setDeleteModal(false)}
      />

      {/* Service Form Modal */}
      {showServicesFormModal && (
        <ServiceForm
          isEdit={isEdit}
          service={isEdit ? service : ''}
          getAllServices={() => {
            getAllServices(page);
          }}
        />
      )}

      <div>
        <Container fluid>
          <h4 className="mb-2 font-size-17">Services</h4>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Col sm="12" className="d-flex justify-content-between align-items-center">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: isActiveTab === true })}
                          onClick={() => setIsActiveTab(true)}
                        >
                          Active
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: isActiveTab === false })}
                          onClick={() => setIsActiveTab(false)}
                        >
                          Inactive
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="text-sm-end mb-3">
                      <Button
                        color="primary"
                        className="font-16 btn-block btn btn-primary"
                        onClick={() => handleServiceClick()}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add Service
                      </Button>
                    </div>
                  </Col>
                  <div className="mt-3">
                    <DataGrid
                      data={services}
                      columns={serviceListColumns}
                      enableColumnResizing={false}
                      enableColumnFilterModes
                      enableGlobalFilter
                      enableEditing={false}
                      selectedRows={selectedRows}
                      onRowSelectionChange={handleRowSelection}
                      totalSize={totalSize}
                      onPageChange={handlePageClick}
                      onPageSizeChange={handleLimitChange}
                      initialPage={page}
                      initialPageSize={size}
                      onSearch={debounceSearch}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

Services.propTypes = {
  onSetLoader: PropTypes.func.isRequired,
  showServicesFormModal: PropTypes.bool.isRequired,
  onSetShowServicesModal: PropTypes.func.isRequired,
};
PriceCell.propTypes = {
  renderedCellValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

ActionsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      price: PropTypes.number,
      duration: PropTypes.number,
      timeBuffer: PropTypes.number,
      isActive: PropTypes.bool,
    }),
  }).isRequired,
  handleServiceClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  toggleActiveStatus: PropTypes.func.isRequired,
};

const mapStateToProps = ({ services }) => ({
  showServicesFormModal: services.showServicesFormModal,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoader: (bool) => dispatch(setLoader(bool)),
  onSetShowServicesModal: () => dispatch(setShowServicesModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
