/* eslint-disable import/prefer-default-export */
import { handleError } from '../errorHandler';
import { axiosGet, axiosPatch } from '../http.service';

export const fetchAssignedPhoneNumbers = async () => {
  try {
    const response = await axiosGet('numbers/assigned');
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to phone numbers');
  } catch (error) {
    handleError('Error fetching phone numbers:', error);
    throw error;
  }
};
export const fetchPurchasedPhoneNumbers = async () => {
  try {
    const response = await axiosGet('numbers/purchased-numbers');
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to fetch purchased phone numbers');
  } catch (error) {
    handleError('Error fetching purchased phone numbers:', error);
    throw error;
  }
};
export const updatePhoneNumbersStatus = async (status) => {
  try {
    const response = await axiosPatch('numbers/update-numbers-status', { status });
    if (response?.status) {
      return response.data;
    }
    throw new Error(response?.message || 'Failed to update phone numbers status');
  } catch (error) {
    handleError('Error updating phone numbers status:', error);
    throw error;
  }
};
